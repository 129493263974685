import { Injectable } from '@angular/core';

import { Action, State, StateContext } from '@ngxs/store';

import { RootStateModel } from 'src/app/shared/models/ngxs/root.models';

import { SetCustomCopyPasteData, ShowSpinner } from './root.actions';

@State<RootStateModel>({
  name: 'root',
  defaults: {
    isSpinner: false,
    customCopyPastedData: undefined,
  },
})
@Injectable()
export class RootState {
  @Action(ShowSpinner)
  showSpinner({ patchState }: StateContext<any>, { isSpinner }: ShowSpinner) {
    patchState({ isSpinner });
  }

  @Action(SetCustomCopyPasteData)
  pasteTheVerificationCode(
    { patchState }: StateContext<RootStateModel>,
    { data }: SetCustomCopyPasteData,
  ) {
    patchState({ customCopyPastedData: data });
  }
}
