<div class="rs-create-password">
  <div class="rs-create-password__row">
    <span>{{ 'registration.createPassword.label' | translate }}</span>
  </div>
  <rs-accounts-form
    actionLabel="actions.createPassword"
    width="460px"
    [questions]="questions"
    [actionMargin]="[20, 0]"
    [isUpperCaseAction]="true"
    [customQuestion]="passwordReliabilityWidget"
    [formValidators]="formValidators"
    (onSubmit)="handleFormSubmit($event)">
  </rs-accounts-form>
</div>

<ng-template
  #passwordReliabilityWidget
  let-context
>
  <rs-accounts-password-strength-widget
    [password]="context.password">
  </rs-accounts-password-strength-widget>
</ng-template>
