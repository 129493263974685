export enum QuestionType {
  Textbox,
  Checkbox,
  Custom,
}

export enum QuestionFormat {
  Text = 'text',
  Password = 'password',
}

export enum QuestionAppearance {
  None = 'none',
  Outline = 'outline',
}
