import {
  QuestionAppearance,
  QuestionFormat,
  QuestionType,
} from '../../enum/question.enum';
import {
  QuestionTextboxFocusHandler,
  QuestionTextboxIconStyle,
  QuestionTextboxKeyButtonPressHandler,
} from '../../types/questions/question-textbox.types';

import { IQuestionBase, QuestionBase } from './question-base';

export interface IQuestionTexbox<T> extends IQuestionBase<T> {
  appearance?: QuestionAppearance;
  type?: QuestionFormat;
  required?: boolean;
  focus?: boolean;
  icon?: string;
  iconStyle?: QuestionTextboxIconStyle;
  hasInitialError?: boolean;
  keyButtons?: string[];
  isDate?: boolean;
  isCustomCopyPaste?: boolean;

  onFocus?: QuestionTextboxFocusHandler;

  onKeyButtonPress?: QuestionTextboxKeyButtonPressHandler;

  setFocus?: QuestionTextboxFocusHandler;
}

export class QuestionTextbox<T = string> extends QuestionBase<T> implements IQuestionTexbox<T> {
  public appearance: QuestionAppearance;
  public type?: QuestionFormat;
  public required?: boolean;
  public focus?: boolean;
  public icon?: string;
  public iconStyle?: QuestionTextboxIconStyle;
  public hasInitialError?: boolean;
  public keyButtons?: string[];
  public isDate?: boolean;
  public isCustomCopyPaste?: boolean;

  public onFocus?: QuestionTextboxFocusHandler;

  public onKeyButtonPress?: QuestionTextboxKeyButtonPressHandler;

  constructor(options: IQuestionTexbox<T>) {
    super({
      ...options,
      controlType: QuestionType.Textbox,
    });

    this.appearance = options.appearance || QuestionAppearance.Outline;
    this.type = options.type || QuestionFormat.Text;
    this.required = options.required || false;
    this.focus = options.focus;
    this.icon = options.icon;
    this.iconStyle = options.iconStyle;
    this.hasInitialError = options.hasInitialError || false;
    this.keyButtons = options.keyButtons || null;
    this.isDate = options.isDate || false;
    this.isCustomCopyPaste = options.isCustomCopyPaste;

    this.onFocus = options.onFocus || null;

    this.onKeyButtonPress = options.onKeyButtonPress || null;
  }

  public setFocus(focus: boolean): void {
    this.focus = !!focus;
  }
}
