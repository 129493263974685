import { AbstractControl } from '@angular/forms';

import { isValidPhoneNumber } from 'libphonenumber-js';

import { EMAIL, ONLY_NUMBERS } from '../constants/regex.constants';

const isEmail = (username: string): boolean => !username.startsWith('+');

export const isPhoneNumber = (username: string): boolean =>
  username.startsWith('+') && ONLY_NUMBERS.test(username.slice(1));

export const usernameValidator = ({ value }: AbstractControl): { [key: string]: any } | null => {
  if (value) {
    if (isEmail(value) && !EMAIL.test(value)) {
      return { email: true };
    } else if (isPhoneNumber(value) && !isValidPhoneNumber(value)) {
      return { phoneNumber: true };
    }
  }

  return null;
};

export const phoneNumberValidator = (required: boolean, countryCode?: string) => {
  return ({ value: input }: AbstractControl): { [key: string]: any } | null => {
    const value = countryCode ? `${countryCode}${input}` : input;

    if (
      (required && phoneValidation(value))
      || (value && phoneValidation(value))
    ) {
      return { phoneNumber: true };
    }

    return null;
  };
};

const phoneValidation = (value: string) => !isPhoneNumber(value) || !isValidPhoneNumber(value);
