import { Component, OnInit } from '@angular/core';
import { ValidatorFn } from '@angular/forms';

import { Store } from '@ngxs/store';

import { CreatePassword } from 'src/app/core/ngxs/registration/registration.actions';
import { QuestionBase } from 'src/app/shared/classes/questions/question-base';
import { QuestionCustom } from 'src/app/shared/classes/questions/question-custom';
import { QuestionTextbox } from 'src/app/shared/classes/questions/question-textbox';
import { QuestionFormat } from 'src/app/shared/enum/question.enum';
import { CreatePasswordContext, CreatePasswordData } from 'src/app/shared/models/registration.models';
import { getQuestion } from 'src/app/shared/utils/questions/questions.utils';
import { hasQueryStringParam } from 'src/app/shared/utils/url.utils';
import { confirmPasswordValidator, passwordStrengthValidator } from 'src/app/shared/validators/password.validators';

@Component({
  selector: 'rs-accounts-create-password',
  templateUrl: './create-password.component.html',
  styleUrls: ['./create-password.component.scss'],
})
export class CreatePasswordComponent implements OnInit {
  public formValidators: ValidatorFn[];
  public questions: QuestionBase[] = [];

  constructor(
    private readonly _store: Store,
  ) {
  }

  ngOnInit(): void {
    this.formValidators = [
      confirmPasswordValidator as ValidatorFn,
    ];
    this.questions = [
      new QuestionTextbox({
        type: QuestionFormat.Password,
        key: 'password',
        label: 'fields.labels.password',
        required: true,
        validators: [
          passwordStrengthValidator,
        ],
        onValueChange: (value: string, questions: QuestionBase[]) =>
          getQuestion<QuestionCustom<CreatePasswordContext>>('passwordStrengthWidget', questions)
            .setTemplateContext({ password: value }),
      }),
      new QuestionCustom<CreatePasswordContext>({
        key: 'passwordStrengthWidget',
        templateContext: { password: '' },
      }),
      new QuestionTextbox({
        type: QuestionFormat.Password,
        key: 'confirmation',
        label: 'fields.labels.confirmation',
        required: true,
      }),
    ];
  }

  public handleFormSubmit({ password, confirmation }: CreatePasswordData): void {
    this._store.dispatch(
      new CreatePassword(
        {
          password,
          confirmation,
        },
        hasQueryStringParam('token'),
      ),
    );
  }
}
