import { Component, OnInit } from '@angular/core';
import { ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

import { Store } from '@ngxs/store';

import { QuestionBase } from 'src/app/shared/classes/questions/question-base';
import { QuestionTextbox } from 'src/app/shared/classes/questions/question-textbox';
import { RegistrationType } from 'src/app/shared/enum/registration.enum';
import * as colors from 'src/app/shared/constants/colors.constants';
import { SendValidationCode } from 'src/app/core/ngxs/registration/registration.actions';
import { QuestionCustom } from 'src/app/shared/classes/questions/question-custom';
import { Map } from 'src/app/shared/models/app.models';
import { phoneNumberValidator } from 'src/app/shared/validators/username.validators';

import { getRegistrationType, getRegistrationTypeData } from '../utils/registration.utils';

@Component({
  selector: 'rs-accounts-validate-data',
  templateUrl: './validate-data.component.html',
  styleUrls: ['./validate-data.component.scss'],
})
export class ValidateDataComponent implements OnInit {
  public RegistrationType: typeof RegistrationType = RegistrationType;

  public registrationType: RegistrationType;
  public questions: QuestionBase[] = [];

  constructor(
    private readonly _store: Store,
    private readonly _route: ActivatedRoute,
  ) {
  }

  ngOnInit(): void {
    this.registrationType = getRegistrationType(this._route);

    this.questions.push(
      this.registrationType === RegistrationType.Email
        ? new QuestionTextbox({
            key: 'email',
            label: this._getQuestionLabel(),
            initialValue: getRegistrationTypeData(this.registrationType),
            validators: this._getQuestionValidators(),
            icon: this._getQuestionIcon(),
            iconStyle: {
              fontSize: '40px',
              color: this._getQuestionIconColor(),
            },
          })
        : new QuestionCustom<any>({
            key: 'cellphone',
            initialValue: getRegistrationTypeData(this.registrationType),
            validators: this._getQuestionValidators(),
          }),
    );
  }

  public handleFormSubmit(data: Map): void {
    this._store.dispatch(
      new SendValidationCode(this.registrationType, data[this.registrationType]),
    );
  }

  private _getQuestionLabel(): string {
    return this.registrationType === RegistrationType.Email
      ? 'fields.labels.emailAddress'
      : 'fields.labels.phone';
  }

  private _getQuestionValidators(): ValidatorFn[] {
    const sharedValidators = [Validators.required];

    return this.registrationType === RegistrationType.Email
      ? [
        ...sharedValidators,
        Validators.email,
      ]
      : [
        ...sharedValidators,
        phoneNumberValidator(true),
      ];
  }

  private _getQuestionIcon(): string {
    return this.registrationType === RegistrationType.Email
      ? 'check_circle'
      : 'check_circle_outline';
  }

  private _getQuestionIconColor(): string {
    return this.registrationType === RegistrationType.Email
      ? colors.BLUE
      : colors.GRAY;
  }
}
