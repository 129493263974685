import { NgModule } from '@angular/core';

import { DirectivesModule } from '../../modules/directives.module';
import { SharedModule } from '../../modules/shared.module';

import { ButtonComponent } from './button.component';

@NgModule({
  imports: [
    SharedModule,
    DirectivesModule,
  ],
  declarations: [
    ButtonComponent,
  ],
  exports: [
    ButtonComponent,
  ],
})
export class ButtonModule {}
