<div
  class="rs-root"
  [class.blur-content]="isSpinner$ | async"
  [class.with-congrats]="isCongratsPage"
>
  <rs-accounts-header *ngIf="!isCongratsPage"></rs-accounts-header>

  <router-outlet></router-outlet>

  <rs-accounts-footer *ngIf="!isCongratsPage"></rs-accounts-footer>
</div>

<rs-accounts-spinner [isSpinner]="isSpinner$ | async"></rs-accounts-spinner>

<simple-notifications></simple-notifications>
