import { NgModule } from '@angular/core';

import { LoginModule } from './login/login.module';
import { PasswordRecoveryModule } from './password-recovery/password-recovery.module';

@NgModule({
  exports: [
    LoginModule,
    PasswordRecoveryModule,
  ],
})
export class AuthModule {}
