import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';

import { Store } from '@ngxs/store';

import { isValidPhoneNumber } from 'libphonenumber-js';

import { EMAIL } from 'src/app/shared/constants/regex.constants';
import { RegistrationType } from 'src/app/shared/enum/registration.enum';
import { getQueryParamValues, getUrl } from 'src/app/shared/utils/url.utils';

import { SendValidationCode } from '../ngxs/registration/registration.actions';
import { RouterService } from '../services/router.service';
import {  getRegistrationTypeData } from '../../registration/utils/registration.utils';

@Injectable()
export class RegistrationGuard implements CanActivate {
  constructor(
    private readonly _store: Store,
    private readonly _routerService: RouterService,
  ) {
  }

  public canActivate(): boolean {
    const url = getUrl();

    if (url === '/registration') {
      let [email, cellphone] = getQueryParamValues(['email', 'cellphone']);

      if (cellphone) {
        cellphone = atob(cellphone).includes('+') ? `${atob(cellphone)}` : `+${atob(cellphone)}`;
      }

      if (email === 'true' || cellphone === 'true') {
        this._routerService.navigate('/login');
      } else if (EMAIL.test(atob(email)) || isValidPhoneNumber(cellphone || '')) {
        const registrationType = email ? RegistrationType.Email : RegistrationType.Cellphone;

        this._store.dispatch(
          new SendValidationCode(
            registrationType,
            getRegistrationTypeData(registrationType)
          ),
        );
      } else {
        return false;
      }
    }

    return true;
  }
}
