<div
  class="question-textbox"
  [class.initial-error]="hasInitialError"
>
  <mat-form-field [appearance]="appearance">
    <mat-label>{{ label | translate }}</mat-label>

    <input
      *ngIf="!isDate"
      matInput
      [formControl]="control"
      [placeholder]="label | translate"
      [type]="inputType"
      [focus]="focus"
      [textboxPattern]="constraints?.pattern"
      [attr.minLength]="constraints?.minLength || null"
      [attr.maxLength]="constraints?.maxLength || null"
      (focus)="handleFocus()"
      (keydown)="handleKeyDown($event)"
      (paste)="isCustomCopyPaste && handleOnPaste($event)" />

    <button
      *ngIf="isPasswordType"
      type="button"
      mat-icon-button matSuffix
      (click)="isPasswordVisible = !isPasswordVisible"
    >
      <mat-icon>{{ isPasswordVisible ? 'visibility' : 'visibility_off' }}</mat-icon>
    </button>

    <ng-container *ngIf="isDate">
      <input
        matInput
        [formControl]="control"
        [placeholder]="label | translate"
        [type]="type"
        [matDatepicker]="picker"
        [textboxPattern]="constraints?.pattern"
        [attr.minLength]="constraints?.minLength || null"
        [attr.maxLength]="constraints?.maxLength || null"
        (focus)="handleFocus(picker)"
        (keydown)="handleKeyDown($event)"
        (paste)="isCustomCopyPaste && handleOnPaste($event)" />

      <!-- <mat-datepicker-toggle
        matPrefix
        [for]="picker">
      </mat-datepicker-toggle> -->

      <mat-datepicker #picker></mat-datepicker>
    </ng-container>

    <mat-error *ngFor="let error of getControlErrors()">
      {{ ('fields.errors.' + error) | translate }}
    </mat-error>

    <mat-icon
      *ngIf="icon"
      matSuffix
      [ngStyle]="iconStyle"
    >
      {{ icon }}
    </mat-icon>
  </mat-form-field>
</div>
