import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDatepicker } from '@angular/material/datepicker';

import { Store } from '@ngxs/store';

import { SetCustomCopyPasteData } from 'src/app/core/ngxs/root/root.actions';
import { QuestionAppearance, InputType } from 'src/app/shared/enum';
import { QuestionBaseConstraints } from 'src/app/shared/models/questions/question-base.models';
import {
  QuestionTextboxFocusHandler,
  QuestionTextboxIconStyle,
  QuestionTextboxKeyButtonPressHandler,
} from 'src/app/shared/types/questions/question-textbox.types';

@Component({
  selector: 'rs-accounts-textbox',
  templateUrl: './textbox.component.html',
  styleUrls: ['./textbox.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TextboxComponent {
  @Input()
  control: FormControl;

  @Input()
  appearance: QuestionAppearance;

  @Input()
  label: string;

  @Input()
  type: string;

  @Input()
  focus: boolean;

  @Input()
  constraints: QuestionBaseConstraints;

  @Input()
  icon: string;

  @Input()
  iconStyle: QuestionTextboxIconStyle;

  @Input()
  hasInitialError: boolean;

  @Input()
  keyButtons: string[];

  @Input()
  isDate: boolean;

  @Input()
  isCustomCopyPaste: boolean;

  @Input()
  onFocus: QuestionTextboxFocusHandler;

  @Input()
  onKeyButtonPress: QuestionTextboxKeyButtonPressHandler;

  public isPasswordVisible: boolean = false;

  public get inputType(): string {
    return this.isPasswordVisible ? InputType.Text : this.type;
  }

  public get isPasswordType(): boolean {
    return this.type === InputType.Password;
  }

  constructor(
    private readonly _store: Store,
  ) {
  }

  public handleFocus(datePicker?: MatDatepicker<string>): void {
    if (this.onFocus) {
      this.onFocus();
    }

    if (this.isDate) {
      datePicker.open();
    }
  }

  public handleKeyDown(event: KeyboardEvent): void {
    if (this.keyButtons?.includes(event.code) && this.onKeyButtonPress) {
      this.onKeyButtonPress(event.code);
    }
  }

  public handleOnPaste(event: ClipboardEvent): void {
    const pastedData = event.clipboardData.getData('text');

    event.preventDefault();

    this._store.dispatch(new SetCustomCopyPasteData(pastedData));
  }

  public getControlErrors(): string[] {
    return Object.keys(this.control.errors || {});
  }
}
