<div class="rs-login">
  <div class="rs-sign-up__title">{{'signUp.welcomeMessage' | translate}}</div>
  <rs-accounts-form
    actionLabel="actions.signUp"
    width="460px"
    [questions]="questions"
    [actionMargin]="[20, 0, 0]"
    [isUpperCaseAction]="true"
    [customQuestion]="phone"
    (onSubmit)="handleSignUpClick($event)">
  </rs-accounts-form>
  <ng-template
    #phone
    let-context
  >
    <rs-accounts-intl-tel-phone-widget [control]="context.control"></rs-accounts-intl-tel-phone-widget>
  </ng-template>

  <div class="rs-sign-up__or">{{ 'login.or' | translate }}</div>

  <rs-accounts-button
    label="actions.login"
    color="blue"
    routerLink="/login"
    queryParamsHandling="merge"
    [margin]="[20, 0, 0]"
    [isUpperCase]="true">
  </rs-accounts-button>
</div>
