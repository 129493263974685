<form
  class="rs-form"
  [ngClass]="classes"
  [style.width]="width"
  [formGroup]="formGroup"
  (ngSubmit)="handleSubmit()"
>
  <div
    *ngFor="let question of questions"
    class="rs-form__question"
  >
    <rs-accounts-question
      [question]="question"
      [formGroup]="formGroup"
      [customQuestion]="customQuestion">
    </rs-accounts-question>
  </div>

  <rs-accounts-button
    *ngIf="withAction"
    [disabled]="formGroup.invalid"
    [color]="formGroup.invalid ? 'gray' : 'blue'"
    [margin]="actionMargin"
    [label]="actionLabel"
    [type]="ButtonType.Submit"
    [isUpperCase]="isUpperCaseAction">
  </rs-accounts-button>
</form>
