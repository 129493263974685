export class ShowSpinner {
  public static readonly type = '[Root] Set Spinner';

  constructor(
    public readonly isSpinner: boolean,
  ) {
  }
}

export class SetCustomCopyPasteData {
  public static readonly type = '[Root] Set Custom Copy Paste Data';

  constructor(
    public readonly data: string,
  ) {
  }
}
