import { AbstractControl, FormGroup } from '@angular/forms';

import { MIN_PASSWORD_LENGTH } from '../constants/password.constants';
import { Map } from '../models/app.models';
import {
  LETTERS,
  LOWER_CASE_LETTERS,
  NUMBERS,
  SPECIAL_SYMBOLS,
  UPPER_CASE_LETTERS,
} from '../constants/regex.constants';

export const confirmPasswordValidator = (form: FormGroup): void => {
  const { password, confirmation } = form.controls;

  if (password && confirmation && (password.value !== confirmation.value)) {
    confirmation.setErrors({ passwordsMatch: true });
  } else {
    confirmation.setErrors(null);
  }
};

export const passwordStrengthValidator = (control: AbstractControl): Map<boolean> | null => {
  const controlValue = control.value as string;

  switch (true) {
    case !controlValue: {
      return { required: true };
    }
    case controlValue?.length < MIN_PASSWORD_LENGTH: {
      return { minlength: true };
    }
    case !LETTERS.test(controlValue): {
      return { noLetters: true };
    }
    case !LOWER_CASE_LETTERS.test(controlValue): {
      return { noLowerCaseLetter: true };
    }
    case !UPPER_CASE_LETTERS.test(controlValue): {
      return { noUpperCaseLetter: true };
    }
    case !NUMBERS.test(controlValue): {
      return { noNumbers: true };
    }
    case !SPECIAL_SYMBOLS.test(controlValue): {
      return { noSymbols: true };
    }
    default: {
      return null;
    }
  }
};
