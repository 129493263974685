import { NgModule } from '@angular/core';

import { SharedModule } from '../../shared/modules/shared.module';
import { FormModule } from '../../shared/components/form/form.module';
import { ButtonModule } from '../../shared/components/button/button.module';

import { PasswordRecoveryComponent } from './password-recovery.component';

@NgModule({
  imports: [
    SharedModule,
    FormModule,
    ButtonModule,
  ],
  declarations: [
    PasswordRecoveryComponent,
  ],
  exports: [
    PasswordRecoveryComponent,
  ],
})
export class PasswordRecoveryModule {}
