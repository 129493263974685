import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';

import { Map } from 'src/app/shared/models/app.models';
import { getQueryString } from 'src/app/shared/utils/url.utils';

@Injectable({ providedIn: 'root' })
export class RouterService {
  constructor(
    private readonly _router: Router,
    private readonly _ngZone: NgZone,
  ) {
  }

  public navigate(
    url: string,
    queryParams: Map<any> = null,
    excludeQueryParams: string[] = [],
  ): void {
    const queryString = getQueryString(queryParams, excludeQueryParams);

    this._ngZone.run(() => {
      queryString && queryString !== 'undefined'
        ? this._router.navigateByUrl(`${url}?${queryString}`)
        : this._router.navigateByUrl(url);
    });
  }

  public hasPathPart(pathPart: string): boolean {
    return this._router.url.includes(pathPart);
  }
}
