import { NgModule } from '@angular/core';

import { RegistrationGuard } from './guards/registration.guard';
import { FooterModule } from './components/footer/footer.module';
import { HeaderModule } from './components/header/header.module';
import { SpinnerModule } from './components/spinner/spinner.module';
import { AuthGuard } from './guards/auth.guard';

@NgModule({
  providers: [
    AuthGuard,
    RegistrationGuard,
  ],
  exports: [
    HeaderModule,
    FooterModule,
    SpinnerModule,
  ],
})
export class CoreModule {}
