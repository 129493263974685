import { RegistrationType } from 'src/app/shared/enum/registration.enum';
import { Map } from 'src/app/shared/models/app.models';
import { CreatePasswordData } from 'src/app/shared/models/registration.models';

export class SendValidationCode {
  public static readonly type = '[Registration] Send Validation Code';

  constructor(
    public readonly registrationType: RegistrationType,
    public readonly data: string,
    public readonly queryParams: Map = {},
    public readonly isResend?: boolean,
  ) {
  }
}

export class ValidateCode {
  public static readonly type = '[Registration] Validate Code';

  constructor(
    public readonly registrationType: RegistrationType,
    public readonly data: string,
    public readonly code: string,
  ) {
  }
}

export class CreatePassword {
  public static readonly type = '[Registration] Create Password';

  constructor(
    public readonly data: CreatePasswordData,
    public readonly isPasswordRecovery: boolean,
  ) {
  }
}

export class SetInvalidValidationCodeFlag {
  public static readonly type = '[Registration] Set Invalid Validation Code Flag';

  constructor(
    public readonly isInvalidValidationCode: boolean,
  ) {
  }
}
