<div class="rs-validate-code">
  <div class="rs-validate-code_header">
    {{ 'registration.validateCode.auth.header' | translate }}
  </div>
  <rs-accounts-form
    width="500px"
    [questions]="questions"
    [direction]="Direction.Row"
    [withAction]="false">
  </rs-accounts-form>
  <div
    *ngIf="registrationType === RegistrationType.Email"
    class="rs-validate-code__warning"
  >
    <mat-icon>warning</mat-icon>
    <span>{{ 'registration.validateCode.warning' | translate }}</span>
  </div>
  <div class="rs-validate-code__notification">
    <span class="label">
      {{ 'registration.validateCode.email.resendCodeLabel' | translate }}
    </span>
    <span
      class="link"
      (click)="handleResendCode()"
    >
      {{ 'registration.validateCode.email.resendCodeLink' | translate }}
    </span>
  </div>
</div>
