<div class="rs-footer">
  <span>{{ 'footer.rights' | translate }}</span>
  <div class="rs-footer_use-terms">
    <span>|</span>
    <a
      href="https://www.rockspoon.com/hubfs/Documents/Terms_of_Use.pdf"
      target="_blank"
    >
      {{ 'footer.useTerms' | translate }}
    </a>
  </div>
  <div class="rs-footer_policy">
    <span>|</span>
    <a
      href="https://www.rockspoon.com/hubfs/Documents/Privacy_Policy.pdf"
      target="_blank"
    >
      {{ 'footer.policy' | translate }}
    </a>
  </div>
</div>
