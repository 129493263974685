import { ElementRef } from '@angular/core';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

// any type is set as the component can be not inside the account project
@Component({
  selector: 'rs-accounts-congrats-page',
  templateUrl: './congrats-page.component.html',
  styleUrls: ['./congrats-page.component.scss'],
})
export class CongratsPageComponent implements OnInit {
  @ViewChild('image')
  public imageElement: ElementRef;

  public userInfo: any;

  constructor(
    private readonly _route: ActivatedRoute,
  ) {
  }
  ngOnInit(): void {
    const { queryParamMap } = this._route.snapshot;

    const src = queryParamMap.get('manager-image').match(/src="(.*?)"/);
    const alt = queryParamMap.get('manager-image').match(/alt="(.*?)"/);
    const zoomLink = queryParamMap.get('zoom-link').match(/\[(.*?)\]/);

    this.userInfo = {
      date: queryParamMap.get('start'),
      name: queryParamMap.get('manager'),
      image: {
        src: src ? src[1].trim() : 'assets/images/spoon_icon.png',
        alt: alt ? alt[1].trim() : 'rockspoon-logo',
      },
      zoomLink: zoomLink ? zoomLink[1].trim() : '',
      zoomPasscode: queryParamMap.get('zoom-pass'),
    };
  }
}
