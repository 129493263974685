import { PasswordCheck } from '../enum/registration.enum';
import { Option } from '../models/button.models';

export const MIN_PASSWORD_LENGTH = 9;

export const PASSWORD_CHECKS: Option<PasswordCheck>[] = [
  {
    key: PasswordCheck.MinCharacters,
    label: 'registration.createPassword.passwordChecks.minCharacters',
    isChecked: false,
  },
  {
    key: PasswordCheck.MinCharacter,
    label: 'registration.createPassword.passwordChecks.minCharacter',
    isChecked: false,
  },
  {
    key: PasswordCheck.MinLowerCaseCharacter,
    label: 'registration.createPassword.passwordChecks.minLowerCaseCharacter',
    isChecked: false,
  },
  {
    key: PasswordCheck.MinUpperCaseCharacter,
    label: 'registration.createPassword.passwordChecks.minUpperCaseCharacter',
    isChecked: false,
  },
  {
    key: PasswordCheck.MinNumber,
    label: 'registration.createPassword.passwordChecks.minNumber',
    isChecked: false,
  },
  {
    key: PasswordCheck.MinSymbol,
    label: 'registration.createPassword.passwordChecks.minSymbol',
    isChecked: false,
  },
];
