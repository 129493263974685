import { NgModule } from '@angular/core';

import { Ng2TelInputModule } from 'ng2-tel-input';

import { NgxMaskModule } from 'ngx-mask';

import { SharedModule } from '../../modules/shared.module';

import { IntlTelPhoneWidgetComponent } from './intl-tel-phone-widget.component';

@NgModule({
  imports: [
    SharedModule,
    Ng2TelInputModule,
    NgxMaskModule.forRoot(),
  ],
  declarations: [
    IntlTelPhoneWidgetComponent,
  ],
  exports: [
    IntlTelPhoneWidgetComponent,
  ],
})
export class IntlTelPhoneWidgetModule {}
