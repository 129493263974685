import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { RegistrationType } from 'src/app/shared/enum/registration.enum';

import { getRegistrationType, getRegistrationTypeData } from '../utils/registration.utils';

@Component({
  selector: 'rs-accounts-validate-code',
  templateUrl: './validate-code.component.html',
  styleUrls: ['./validate-code.component.scss'],
})
export class ValidateCodeComponent implements OnInit {
  public registrationType: RegistrationType;
  public registrationTypeData: string;

  constructor(
    private readonly _route: ActivatedRoute,
  ) {
  }

  ngOnInit(): void {
    this.registrationType = getRegistrationType(this._route);
    this.registrationTypeData = getRegistrationTypeData(this.registrationType);
  }

  // public handleEditDataClick(): void {
  //   this._routerService.navigate(
  //     `/registration/validate/${this.registrationType}`,
  //     this._routerService.hasPathPart('/register/')
  //       ? { hasRegisterPath: true }
  //       : null,
  //   );
  // }
}
