import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { RegistrationType } from 'src/app/shared/enum/registration.enum';
import { Map } from 'src/app/shared/models/app.models';
import {
  CreatePasswordData,
  CreatePasswordRequestData,
  CreatePasswordResponse,
  ValidateCodeResponse,
} from 'src/app/shared/models/registration.models';
import { getQueryParamValue, getQueryParamValues } from 'src/app/shared/utils/url.utils';

import { ApiService } from './api.service';

@Injectable({ providedIn: 'root' })
export class RegistrationService {
  constructor(
    private readonly _apiService: ApiService,
  ) {
  }

  public sendValidationCode(
    registrationType: RegistrationType,
    registrationTypeValue: string,
  ): Observable<void> {
    const validateCodeType = registrationType === RegistrationType.Email
      ? 'email'
      : 'phone';

    return this._apiService.post(
      `user-profile/v1/validate/${validateCodeType}`,
      { value: registrationTypeValue },
    );
  }

  public validateCode(
    registrationType: RegistrationType,
    registrationTypeValue: string,
    code: string,
  ): Observable<ValidateCodeResponse> {
    const validateCodeType = registrationType === RegistrationType.Email
      ? 'email'
      : 'sms';

    return this._apiService.put<{ code: string }, ValidateCodeResponse>(
      `user-profile/v1/validate/${validateCodeType}/${btoa(registrationTypeValue)}/code`,
      { code },
    );
  }

  public createPassword(
    data: CreatePasswordData,
    isPasswordRecovery: boolean,
  ): Observable<CreatePasswordResponse> {
    const url = isPasswordRecovery
      ? 'authentication/v1/recovery/password/confirm'
      : 'user-profile/v1/profile/new';

    return this._apiService.post<CreatePasswordRequestData, CreatePasswordResponse>(
      url,
      this._getCreatePasswordRequestData(data, isPasswordRecovery),
    );
  }

  private _getCreatePasswordRequestData(
    data: CreatePasswordData,
    isPasswordRecovery: boolean,
  ): CreatePasswordRequestData {
    if (isPasswordRecovery) {
      return {
        ...data,
        token: getQueryParamValue('token'),
      };
    } else {
      const createPasswordData: Map<any> = { ...data };
      const createPasswordDataKeys = ['email', 'emailValidation', 'firstName', 'lastName', 'birthDate'];

      if (getQueryParamValue('cellphone')) {
        createPasswordDataKeys.push('cellphone', 'phoneValidation');
      }

      const createPasswordDataValues = getQueryParamValues(createPasswordDataKeys);
  
      createPasswordDataKeys.forEach((key, index) => {
        if (key === 'cellphone') {
          const phoneNumber = atob(createPasswordDataValues[index]);

          if (phoneNumber) {
            createPasswordData['phone'] = {
              phoneNumber: phoneNumber.startsWith('+') ? phoneNumber : `+${phoneNumber}`,
            };
          }
        } else if (key === 'email') {
          createPasswordData['email'] =  atob(createPasswordDataValues[index]);
        } else {
          createPasswordData[key] = createPasswordDataValues[index];
        }
      });
  
      return createPasswordData as CreatePasswordRequestData;
    }
  }
}
