<div class="rs-validate-data">
  <div class="header">
    <div
      *ngIf="registrationType === RegistrationType.Email"
      class="email"
    >
      {{ 'registration.edit.email.header' | translate }}
    </div>

    <div
      *ngIf="registrationType === RegistrationType.Cellphone"
      class="phone"
    >
      <span>{{ 'registration.edit.phone.header' | translate }}</span>
      <mat-icon>lock</mat-icon>
    </div>
  </div>
  <div
    class="label"
    [class.email]="registrationType === RegistrationType.Email"
    [class.phone]="registrationType === RegistrationType.Cellphone"
  >
    {{ (registrationType === RegistrationType.Email ? 'registration.edit.email.label' : 'registration.edit.phone.label') | translate }}
  </div>
  <div
    class="field"
    [class.phone]="registrationType === RegistrationType.Cellphone"
  >
    <rs-accounts-form
      actionLabel="actions.sendCode"
      width="460px"
      [actionMargin]="[registrationType === RegistrationType.Cellphone ? 15 : 20, 0, 0]"
      [customQuestion]="phone"
      [isUpperCaseAction]="true"
      [questions]="questions"
      (onSubmit)="handleFormSubmit($event)">
    </rs-accounts-form>
  </div>
</div>

<ng-template
  #phone
  let-context
>
  <rs-accounts-intl-tel-phone-widget
    [control]="context.control">
  </rs-accounts-intl-tel-phone-widget>
</ng-template>
