import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[textboxPattern]',
})
export class TextboxPatternDirective {
  @Input()
  textboxPattern: string | RegExp;

  constructor(
    private readonly _elementRef: ElementRef<HTMLFormElement>,
  ) {
  }

  @HostListener('input', ['$event']) onInputChange(): void {
    const value = this._elementRef.nativeElement.value;

    if (this.textboxPattern && value.replace(this.textboxPattern, '') !== value) {
      this._elementRef.nativeElement.value = value
        ? value.replace(this.textboxPattern, '')
        : '';

      this._updateModelValue();
    }
  }

  private _updateModelValue(): void {
    const event: Event = document.createEvent('Event');

    event.initEvent('input', true, true);

    Object.defineProperty(event, 'target', { value: this._elementRef.nativeElement, enumerable: true });

    this._elementRef.nativeElement.dispatchEvent(event);
  }
}
