import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';

import { Select } from '@ngxs/store';

import { Observable } from 'rxjs';

import { AppState } from './shared/models/ngxs/app.models';

// remove all staff relating to the congrats page it's needed in the future

@Component({
  selector: 'rs-accounts-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  @Select((state: AppState) => state.root.isSpinner) isSpinner$: Observable<boolean>;

  public isCongratsPage: boolean;

  constructor(
    private readonly _translateService: TranslateService,
    private readonly _router: Router,
  ) {
  }

  ngOnInit(): void {
    this.setLanguage();
  }

  ngAfterViewChecked(): void {
    setTimeout(() => this.isCongratsPage = this._router.url.includes('/congrats'));
  }

  private setLanguage(): void {
    this._translateService.use(this._translateService.getBrowserLang());
  }
}
