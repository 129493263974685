<div class="rs-password-recovery">
  <div class="rs-password-recovery__row">
    <span>{{ 'passwordRecovery.label' | translate }}</span>
  </div>
  <rs-accounts-form
    actionLabel="actions.recoveryLink"
    width="460px"
    [questions]="questions"
    [actionMargin]="[20, 0, 25]"
    [isUpperCaseAction]="true"
    (onSubmit)="handleRecoverPasswordClick($event)">
  </rs-accounts-form>
  <div class="rs-password-recovery__row">
    <span>{{ 'login.noAccount' | translate }}</span>
    <rs-accounts-button
      label="actions.signup"
      color="blue"
      [margin]="[25, 0, 25]"
      [isUpperCase]="true"
      [withIcon]="true"
      (onClick)="handleSignupButtonClick()">
    </rs-accounts-button>
    <span>{{ 'login.or' | translate }}</span>
    <rs-accounts-button
      label="actions.login"
      color="blue"
      [margin]="[25, 0, 25]"
      [isUpperCase]="true"
      [withIcon]="true"
      (onClick)="handleLoginButtonClick()">
    </rs-accounts-button>
  </div>
</div>
