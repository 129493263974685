import { Component, Input, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';

import { parsePhoneNumber } from 'libphonenumber-js';

import * as phoneMasks from '../../../../assets/masks/phone-masks.json';

import {
  ChangeCountryEvent,
  IntlTelPhoneOptions,
  PhoneMask,
} from '../../models/widgets/intl-tel-phone-widget.models';
import { getQueryParamValue } from '../../utils/url.utils';
import { phoneNumberValidator } from '../../validators/username.validators';

@Component({
  selector: 'rs-accounts-intl-tel-phone-widget',
  templateUrl: './intl-tel-phone-widget.component.html',
  styleUrls: ['./intl-tel-phone-widget.component.scss'],
})
export class IntlTelPhoneWidgetComponent implements OnInit {
  @Input()
  control: FormControl;

  public intlTelPhoneOptions: IntlTelPhoneOptions;

  public middlewareControl: FormControl;

  public isTouched: boolean;

  private _prefix: string;
  private _mask: string;

  ngOnInit(): void {
    this._initValues();
    this._initSubscriptions();
  }

  public getNumber(): void {
    this.control.setErrors(null);
  }

  public handleClick(): void {
    this.isTouched = true;
  }

  public getPrefix(): string {
    return this.isTouched ? this._prefix : null;
  }

  public getMask(): string {
    return this.isTouched ? this._mask : null;
  }

  public handleCountryChange({ iso2 }: ChangeCountryEvent): void {
    if ((phoneMasks as PhoneMask)[iso2]) {
      const [prefix, mask] = (phoneMasks as PhoneMask)[iso2]?.split(' ');
      this.middlewareControl.clearValidators();
      this.middlewareControl.setValidators([Validators.required, phoneNumberValidator(false, prefix)]);

      this._prefix = prefix;
      this._mask = mask;
      this.isTouched = true;
    }
  }

  private _initValues(): void {
    this.middlewareControl = new FormControl('', Validators.required);

    this._prefix = '+1';
    this._mask = '(000) 000-0000';

    this.intlTelPhoneOptions = {
      initialCountry: null,
    };

    let number = getQueryParamValue('cellphone');

    number &&= atob(number);

    if (number) {
      number = number.includes('+') ? number : `+${number}`;

      const country = parsePhoneNumber(number).country.toLocaleLowerCase();
      const phoneMask = (phoneMasks as PhoneMask)[country];

      this.middlewareControl.setValue(number);

      this._prefix = phoneMask.split(' ')[0];
      this._mask = phoneMask.split(' ')[1];

      this.intlTelPhoneOptions = {
        initialCountry: country,
      };

      setTimeout(() => {
        if (number) {
          this.control.reset(this.middlewareControl.value);
        } else {
          this.control.setErrors(this.middlewareControl.errors);
        }
      });
    }
  }

  private _initSubscriptions(): void {
    this.middlewareControl.valueChanges.subscribe(() => {
      this.control.reset(`${this._prefix}${this.middlewareControl.value}`);
    });
  }
}
