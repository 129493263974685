import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { RegistrationType } from 'src/app/shared/enum/registration.enum';

import { getRegistrationType, getRegistrationTypeData } from '../../utils/registration.utils';

@Component({
  selector: 'rs-accounts-successfull-code-validation',
  templateUrl: './successfull-code-validation.component.html',
  styleUrls: ['./successfull-code-validation.component.scss'],
})
export class SuccessfullCodeValidationComponent implements OnInit {
  public registrationType: RegistrationType;
  public registrationTypeData: string;

  constructor(
    private readonly _route: ActivatedRoute,
  ) {
  }

  ngOnInit(): void {
    this.registrationType = getRegistrationType(this._route);
    this.registrationTypeData = getRegistrationTypeData(this.registrationType);
  }
}
