import { ActivatedRoute } from '@angular/router';

import { UrlDataType } from 'src/app/shared/enum/app.enum';
import { RegistrationType } from 'src/app/shared/enum/registration.enum';
import { getQueryParamValue, getUrlParamValue } from 'src/app/shared/utils/url.utils';

export const getRegistrationType = (route: ActivatedRoute): RegistrationType =>
  getUrlParamValue(route, UrlDataType.Param, 'type') === 'email'
    ? RegistrationType.Email
    : RegistrationType.Cellphone;

export const getRegistrationTypeData = (registrationType: RegistrationType): string => {
  const data = atob(getQueryParamValue(registrationType));

  if (registrationType === RegistrationType.Cellphone) {
    return data.startsWith('+') ? data : `+${data}`;
  }

  return data;
};
