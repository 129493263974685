import { Component, Input, TemplateRef } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { QuestionBase } from 'src/app/shared/classes/questions/question-base';
import { QuestionType } from 'src/app/shared/enum/question.enum';

@Component({
  selector: 'rs-accounts-question',
  templateUrl: './question.component.html',
  styleUrls: ['./question.component.scss'],
})
export class QuestionComponent {
  @Input()
  question: QuestionBase;

  @Input()
  formGroup: FormGroup;

  @Input()
  customQuestion: TemplateRef<any>;

  public readonly QuestionType: typeof QuestionType = QuestionType;
}
