import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';

import { Position } from '../../enum/app.enum';
import { ButtonType } from '../../enum/button.enum';

@Component({
  selector: 'rs-accounts-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent implements OnChanges {
  @Input()
  position: Position = Position.Center;

  @Input()
  type: ButtonType = ButtonType.Button;

  @Input()
  label: string;

  @Input()
  isUpperCase: boolean;

  @Input()
  withIcon: boolean;

  @Input()
  icon: string;

  @Input()
  color: string;

  @Input()
  disabled: boolean;

  @Input()
  margin: number[] = [];

  @Input()
  path: string;

  @Output()
  onClick: EventEmitter<void> = new EventEmitter<void>();

  public classes: any;

  ngOnChanges(): void {
    this.classes = {
      left: this.position === Position.Left,
      center: this.position === Position.Center,
      right: this.position === Position.Right,
      [this.color]: true,
    };
  }

  public handleClick(): void {
    this.onClick.emit();
  }
}
