import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { ClientMap } from 'src/app/shared/types/user.types';

import { ApiService } from './api.service';

@Injectable({ providedIn: 'root' })
export class UserService {
  constructor(
    private readonly _apiService: ApiService,
  ) {
  }

  public fetchClientUrls(accessToken: string): Observable<ClientMap> {
    return this._apiService.get(
      'tenant-management/v1/product/urls',
      { 'access_token': accessToken },
    );
  }
}
