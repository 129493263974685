import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { SharedModule } from '../shared/modules/shared.module';

import { CreatePasswordComponent } from './create-password/create-password.component';
import { ValidateDataComponent } from './validate-data/validate-data.component';
import { ValidateCodeComponent } from './validate-code/validate-code.component';
import { SignUpComponent } from './sign-up/sign-up.component';
import {
  SuccessfullCodeValidationComponent,
} from './validate-code/successfull-code-validation/successfull-code-validation.component';

const routes: Routes = [
  {
    path: 'default-registration',
    component: SignUpComponent,
  },
  {
    path: 'create-password',
    component: CreatePasswordComponent,
  },
  {
    path: 'validate-code/:type',
    component: ValidateCodeComponent,
  },
  {
    path: 'validate-code/:type/success',
    component: SuccessfullCodeValidationComponent,
  },
  {
    path: 'validate-code/register/:type',
    component: ValidateCodeComponent,
  },
  {
    path: 'validate/:type',
    component: ValidateDataComponent,
  },
];

@NgModule({
  imports: [
    SharedModule,
    RouterModule.forChild(routes),
  ],
  exports: [
    RouterModule,
  ],
})
export class RegistrationRoutingModule {}
