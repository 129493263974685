<div
  class="rs-button"
  [ngClass]="classes"
  [m]="margin"
>
  <button
    *ngIf="!path"
    mat-raised-button
    [disabled]="disabled"
    [type]="type"
    (click)="handleClick()"
  >
    <span>{{ isUpperCase ? (label | translate | uppercase) : (label | translate) }}</span>
  </button>

  <button
    *ngIf="path"
    mat-raised-button
    [disabled]="disabled"
    [type]="type"
    [routerLink]="[path]"
  >
    <span>{{ isUpperCase ? (label | translate | uppercase) : (label | translate) }}</span>
  </button>
</div>
