import { Directive, ElementRef, Input, OnInit } from '@angular/core';

@Directive({
  selector: '[p]',
})
export class PaddingDirective implements OnInit {
  @Input()
  p: number[];

  constructor(
    private readonly _elementRef: ElementRef,
  ) {
  }

  ngOnInit(): void {
    switch (this.p.length) {
      case 1: {
        this.setPadding(this.p[0], this.p[0], this.p[0], this.p[0]);

        break;
      }
      case 2: {
        this.setPadding(this.p[0], this.p[1], this.p[0], this.p[1]);

        break;
      }
      case 3: {
        this.setPadding(this.p[0], this.p[1], this.p[2], this.p[1]);
        
        break;
      }
      case 4: {
        this.setPadding(this.p[0], this.p[1], this.p[2], this.p[3]);

        break;
      }
      default: {
        return;
      }
    }
  }

  private setPadding(
    top: number,
    right: number,
    bottom: number,
    left: number,
  ): void {
    this._elementRef.nativeElement.style.paddingTop = `${top}px`;
    this._elementRef.nativeElement.style.paddingRight = `${right}px`;
    this._elementRef.nativeElement.style.paddingBottom = `${bottom}px`;
    this._elementRef.nativeElement.style.paddingLeft = `${left}px`;
  }
}
