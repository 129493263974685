<div class="rs-warning">
  <span class="rs-warning_header">{{ ('login.warnings.' + warningKey + 'Header') | translate }}</span>
  <span class="rs-warning__message">
    <span class="rs-warning__message_text">{{ ('login.warnings.' + warningKey + 'Message') | translate }}</span>
    <span
      *ngIf="warningKey === 'incorrectCredentials'"
      class="rs-warning__message_link"
    >
      <span
        class="rs-warning__message_link_text"
        (click)="handleForgotPasswordClick()"
      >
        {{ 'login.forgotPassword' | translate }}
      </span>
    </span>
    <span class="rs-warning__message_dot">.</span>
  </span>
</div>
