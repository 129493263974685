import { NgModule } from '@angular/core';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { NgxsModule } from '@ngxs/store';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { SimpleNotificationsModule } from 'angular2-notifications';

import { CookieService } from 'ngx-cookie-service';

import { environment } from 'src/environments/environment';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DEFAULT_LANG } from './shared/constants/app.constants';
import { CoreModule } from './core/core.module';
import { RegistrationModule } from './registration/registration.module';
import { SharedModule } from './shared/modules/shared.module';
import { RootState } from './core/ngxs/root/root.state';
import { AuthState } from './core/ngxs/auth/auth.state';
import { ErrorHandlerInterceptor } from './core/interceptors/error-handler.interceptor';
import { RegistrationState } from './core/ngxs/registration/registration.state';
import { AuthModule } from './auth/auth.module';
import { CongratsPageComponent } from './congrats-page/congrats-page.component';
import { CookieHandlerInterceptor } from './core/interceptors/cookie-handler.interceptor';

const HttpLoaderFactory = (httpClient: HttpClient) =>
  new TranslateHttpLoader(httpClient);

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    SharedModule,
    CoreModule,
    AuthModule,
    RegistrationModule,
    NgxsReduxDevtoolsPluginModule.forRoot(),
    NgxsModule.forRoot(
      [
        RootState,
        AuthState,
        RegistrationState,
      ],
      { developmentMode: !environment.production },
    ),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
      defaultLanguage: DEFAULT_LANG,
    }),
    SimpleNotificationsModule.forRoot(),
  ],
  declarations: [
    AppComponent,
    CongratsPageComponent,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorHandlerInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CookieHandlerInterceptor,
      multi: true,
    },
    CookieService,
  ],
  bootstrap: [
    AppComponent,
  ],
})
export class AppModule { }
