import { Injectable } from '@angular/core';

import { Action, State, StateContext, Store } from '@ngxs/store';

import { TranslateService } from '@ngx-translate/core';

import { EMPTY } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { Client } from 'src/app/shared/enum/user.enum';
import { AuthStateModel } from 'src/app/shared/models/ngxs/auth.model';
import { isPhoneNumber } from 'src/app/shared/validators/username.validators';
import { getQueryParamValue } from 'src/app/shared/utils/url.utils';

import { UserService } from '../../services/user.service';
import { LocalStorageService } from '../../services/local-storage.service';
import { AuthService } from '../../services/auth.service';
import { NotificationService } from '../../services/notification.service';
import { ApiService } from '../../services/api.service';

import { ShowSpinner } from '../root/root.actions';

import { Login, LoginSocial, RecoverPassword } from './auth.actions';

@State<AuthStateModel>({
  name: 'auth',
  defaults: {
    isIncorrectCredentials: false,
  },
})
@Injectable()
export class AuthState {
  constructor(
    private readonly _store: Store,
    private readonly _apiService: ApiService,
    private readonly _authService: AuthService,
    private readonly _userService: UserService,
    private readonly _localStorageService: LocalStorageService,
    private readonly _notificationService: NotificationService,
    private readonly _translateService: TranslateService,
  ) {
  }

  @Action(Login)
  public login(
    { patchState }: StateContext<AuthStateModel>,
    { data }: Login,
  ) {
    const { username, password } = data;

    this._store.dispatch(new ShowSpinner(true));

    return this._authService.login({ username, password })
      .pipe(
        switchMap(({ accessToken }) => {
          return this._userService.fetchClientUrls(accessToken)
            .pipe(
              map(clientUrlsResponse => {
                this._store.dispatch(new ShowSpinner(false));

                const client = getQueryParamValue('client');
                
                if (!client?.trim()) {
                  location.href = `https://${clientUrlsResponse['BCKOF' as Client]}`;
                } else {
                  location.href = `https://${clientUrlsResponse[client as unknown as Client]}`;
                }
              }),
            );
        }),
        catchError(() => {
          patchState({ isIncorrectCredentials: true });
  
          return EMPTY;
        }),
      );
  }

  @Action(LoginSocial)
  public loginSocial(
    context: StateContext<AuthStateModel>,
    { socialLoginType }: LoginSocial,
  ) {
    this._localStorageService.setItem('client', getQueryParamValue('client'));

    location.href = `${this._apiService.getApiUrl()}/authentication/v1/login/${socialLoginType}`;
  }

  @Action(RecoverPassword)
  recoverPassword(
    context: StateContext<AuthStateModel>,
    { data }: RecoverPassword,
  ) {
    this._store.dispatch(new ShowSpinner(true));

    return this._authService.recoverPassword({
      ...data,
      client: getQueryParamValue('client') || 'RCKAP',
    })
      .subscribe(() => {
        this._store.dispatch(new ShowSpinner(false));

        /*
          TODO: later need to check data.username
          if it's email, just show the recoverLinkMessage showSuccessMessage
          otherwise, redirect user to the validate-code/cellphone page
          and set cellphone query param
        */

        const notificationTranslationKey = isPhoneNumber(data.username)
          ? 'passwordRecovery.recoverLinkCellphoneMessage'
          : 'passwordRecovery.recoverLinkEmailMessage';

        this._notificationService.showSuccessMessage(
          this._translateService.instant(notificationTranslationKey),
        );
      });
  }
}
