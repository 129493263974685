export enum RegistrationType {
  Email = 'email',
  Cellphone = 'cellphone',
}

export enum PasswordCheck {
  MinCharacters,
  MinCharacter,
  MinLowerCaseCharacter,
  MinUpperCaseCharacter,
  MinNumber,
  MinSymbol,
}

export enum PasswordCheckCount {
  One = 1,
  Two = 2,
  Three = 3,
  Four = 4,
  Five = 5,
  Six = 6,
}

export enum PasswordStrengthLabel {
  Weak = 'registration.createPassword.passwordStrength.week',
  Moderate = 'registration.createPassword.passwordStrength.moderate',
  Strong = 'registration.createPassword.passwordStrength.strong',
  VeryStrong = 'registration.createPassword.passwordStrength.veryStrong',
}

export enum PasswordStrengthValue {
  Weak = 10,
  Moderate = 30,
  Strong = 70,
  VeryStrong = 100,
  None = 0,
}

export enum PasswordStrengthColor {
  Weak = 'weak',
  Moderate = 'moderate',
  Strong = 'strong',
  VeryStrong = 'very-strong',
}
