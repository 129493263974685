import { AfterViewInit, ElementRef } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { ValidatorFn, Validators } from '@angular/forms';

import * as moment from 'moment';

import { RouterService } from 'src/app/core/services/router.service';
import { QuestionBase } from 'src/app/shared/classes/questions/question-base';
import { QuestionCustom } from 'src/app/shared/classes/questions/question-custom';
import { QuestionTextbox } from 'src/app/shared/classes/questions/question-textbox';
import { QuestionCheckbox } from 'src/app/shared/classes/questions/question.checkbox';
import { EMAIL } from 'src/app/shared/constants/regex.constants';
import { QuestionFormat } from 'src/app/shared/enum/question.enum';
import { DefaultRegistrationData } from 'src/app/shared/models/registration.models';
import { signUpFormUpValidator } from 'src/app/shared/validators/sign-up.validators';
import { phoneNumberValidator } from 'src/app/shared/validators/username.validators';

@Component({
  selector: 'rs-sign-up',
  templateUrl: 'sign-up.component.html',
  styleUrls: ['sign-up.component.scss'],
})
export class SignUpComponent implements OnInit, AfterViewInit {
  public questions: QuestionBase[];
  public signUpFormValidators: ValidatorFn[];

  constructor(
    private _elementRef: ElementRef,
    private _routerService: RouterService,
  ) {
  }

  ngOnInit() {
    this.initFormValidators();
    this.initQuestions();
  }

  ngAfterViewInit() {
    const [termsLink, privacyPolicyLink] = this._elementRef.nativeElement.querySelectorAll('.rs-link');

    termsLink.addEventListener(
      'click',
      (event: MouseEvent) => this._handleLinkClick(event, 'Terms_of_Use'),
    );

    privacyPolicyLink.addEventListener(
      'click',
      (event: MouseEvent) => this._handleLinkClick(event, 'Privacy_Policy'),
    );
  }

  public handleSignUpClick(signUpData: DefaultRegistrationData): void {
    this._routerService.navigate('/registration', {
      firstName: signUpData.firstName,
      lastName: signUpData.lastName,
      email: btoa(signUpData.email),
      cellphone: btoa(signUpData.cellphone),
      birthDate: moment(signUpData.birthDate).format('YYYY-MM-DD'),
      hasRegisterPath: true
    });

    setTimeout(() => location.reload());
  }

  private _handleLinkClick(
    event: MouseEvent,
    document: string,
  ): void {
    event.preventDefault();

    window.open(`../../assets/files/${document}.pdf`);
  }

  private initFormValidators(): void {
    this.signUpFormValidators = [signUpFormUpValidator as ValidatorFn];
  }

  private initQuestions(): void {
    this.questions = [
      new QuestionTextbox({
        type: QuestionFormat.Text,
        key: 'firstName',
        label: 'fields.labels.firstName',
        initialValue: '',
        hasInitialError: false,
        required: true,
        validators: [Validators.required],
      }),
      new QuestionTextbox({
        type: QuestionFormat.Text,
        key: 'lastName',
        label: 'fields.labels.lastName',
        initialValue: '',
        hasInitialError: false,
        required: true,
        validators: [Validators.required],
      }),
      new QuestionTextbox({
        type: QuestionFormat.Text,
        key: 'email',
        label: 'fields.labels.email',
        initialValue: '',
        hasInitialError: false,
        isDate: false,
        required: true,
        validators: [Validators.pattern(EMAIL), Validators.required],
      }),
      new QuestionTextbox({
        type: QuestionFormat.Text,
        key: 'birthDate',
        label: 'fields.labels.birthDate',
        initialValue: '',
        hasInitialError: false,
        required: true,
        isDate: true,
        validators: [Validators.required],
      }),
      new QuestionCustom({
        key: 'cellphone',
        validators: [phoneNumberValidator(false)],
      }),
      new QuestionCheckbox({
        key: 'agreement',
        innerHtml: 'I accept <span class="rs-link">the terms</span> and <span class="rs-link">the privacy policy</span>',
        initialValue: false,
        validators: [Validators.required],
      }),
    ];
  }
}
