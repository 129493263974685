import { FormGroup } from '@angular/forms';

export const signUpFormUpValidator = ((form: FormGroup): void => {
  const { email, cellphone } = form.controls;

  if (!email.value && !cellphone.value) {
    setTimeout(() => {
      form.setErrors({ emailOrCellphoneIsRequired: true });
    });
  } else {
    form.setErrors(null);
  }
});
