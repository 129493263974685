import { Component, OnDestroy, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { Select, Store } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';

import { SocialLoginType } from 'src/app/shared/enum/auth.enum';
import { AppState } from 'src/app/shared/models/ngxs/app.models';
import { getQueryParamValue } from 'src/app/shared/utils/url.utils';
import { Login, LoginSocial } from '../../core/ngxs/auth/auth.actions';
import { RouterService } from '../../core/services/router.service';
import { QuestionBase } from '../../shared/classes/questions/question-base';
import { QuestionTextbox } from '../../shared/classes/questions/question-textbox';
import { QuestionFormat } from '../../shared/enum/question.enum';
import { LoginData } from '../../shared/models/auth.models';
import { usernameValidator } from '../../shared/validators/username.validators';

@Component({
  selector: 'rs-accounts-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
  @Select((state: AppState) => state.auth.isIncorrectCredentials) isIncorrectCredentials$: Observable<boolean>;

  public SocialLoginType: typeof SocialLoginType = SocialLoginType;

  public questions: QuestionBase[];

  public warningKey: string | null;

  private _incorrectCredentialsSubscription: Subscription;

  constructor(
    private readonly _router: Router,
    private readonly _store: Store,
    private readonly _routerService: RouterService,
  ) {
  }

  ngOnInit(): void {
    this._incorrectCredentialsSubscription = this.isIncorrectCredentials$
      .subscribe(isIncorrectCredentials => {
        this.questions = [
          new QuestionTextbox({
            type: QuestionFormat.Text,
            key: 'username',
            label: 'fields.labels.username',
            initialValue: '',
            hasInitialError: isIncorrectCredentials,
            required: true,
            validators: [
              Validators.required,
              usernameValidator,
            ],
          }),
          new QuestionTextbox({
            type: QuestionFormat.Password,
            key: 'password',
            label: 'fields.labels.password',
            initialValue: '',
            hasInitialError: isIncorrectCredentials,
            required: true,
            validators: [Validators.required],
          }),
        ];

        this.warningKey = this._getWarningKey(
          isIncorrectCredentials,
          getQueryParamValue('isRegistration'),
        );
      });
  }

  ngOnDestroy(): void {
    this._incorrectCredentialsSubscription.unsubscribe();
  }

  public handleLoginClick(data: LoginData): void {
    this._store.dispatch(new Login(data));
  }

  public handleForgotPasswordClick(): void {
    this._routerService.navigate('/password-recovery');
  }

  public handleSocialLoginClick(socialLoginType: SocialLoginType): void {
    this._store.dispatch(new LoginSocial(socialLoginType));
  }

  public handleSignupButtonClick(): void {
    this._routerService.navigate('/default-registration');
  }

  private _getWarningKey(
    isIncorrectCredentials: boolean,
    isRegistration: string,
  ): string | null {
    if (isIncorrectCredentials) {
      return 'incorrectCredentials';
    }

    if (isRegistration === 'true') {
      return 'userExists';
    }

    return null;
  }
}
