<div class="rs-validate-code">
  <div class="rs-validate-code__row">
    <span class="rs-header">{{ 'registration.validateCode.' + registrationType + '.header' | translate }}</span>
    <span class="rs-label">{{ 'registration.validateCode.' + registrationType + '.label' | translate }}</span>
    <span class="rs-data">{{ registrationTypeData }}</span>
    <!-- <span
      class="link"
      (click)="handleEditDataClick()"
    >
      {{ 'registration.validateCode.' + registrationType + '.edit' | translate }}
    </span> -->
  </div>

  <div class="rs-validate-code__row">
    <rs-accounts-validate-code-widget></rs-accounts-validate-code-widget>
  </div>
</div>
