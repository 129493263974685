import { NgModule } from '@angular/core';

import { FocusDirective } from '../directives/focus.directive';
import { MarginDirective } from '../directives/margin.directive';
import { PaddingDirective } from '../directives/padding.directive';
import { TextboxPatternDirective } from '../directives/textbox-pattern.directive';

@NgModule({
  declarations: [
    MarginDirective,
    PaddingDirective,
    TextboxPatternDirective,
    FocusDirective,
  ],
  exports: [
    MarginDirective,
    PaddingDirective,
    TextboxPatternDirective,
    FocusDirective,
  ],
})
export class DirectivesModule {}
