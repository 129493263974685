import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class LocalStorageService {
  public setItems(items: { [key: string]: any }): void {
    Object.keys(items).forEach(key => localStorage.setItem(key, items[key]));
  }

  public setItem(
    key: string,
    value: any,
  ): void {
    localStorage.setItem(key, value);
  }

  public getItem(key: string): string | null {
    return localStorage.getItem(key);
  }

  public removeItem(key: string): void {
    localStorage.removeItem(key);
  }
}
