import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { AuthData, AuthResponse, ProfileResponse, RecoverPasswordData } from 'src/app/shared/models/auth.models';

import { ApiService } from './api.service';

@Injectable({ providedIn: 'root' })
export class AuthService {
  constructor(
    private readonly _apiService: ApiService,
  ) {
  }

  public login(data: AuthData): Observable<AuthResponse> {
    return this._apiService.post<AuthData, AuthResponse>('authentication/v1/user/login', data);
  }

  public recoverPassword(data: RecoverPasswordData): Observable<void> {
    return this._apiService.post<RecoverPasswordData, void>('authentication/v1/recovery/password', data);
  }

  public fetchProfile(accessToken: string): Observable<ProfileResponse> {
    return this._apiService.get<ProfileResponse>(
      'user-profile/v1/profile/me',
      { access_token: accessToken },
    );
  }
}
