import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { Select, Store } from '@ngxs/store';

import { cloneDeep } from 'lodash';

import { Observable } from 'rxjs';

import {
  SendValidationCode,
  SetInvalidValidationCodeFlag,
  ValidateCode,
} from 'src/app/core/ngxs/registration/registration.actions';
import {
  getRegistrationType,
  getRegistrationTypeData,
} from 'src/app/registration/utils/registration.utils';

import { QuestionBase } from '../../classes/questions/question-base';
import { QuestionTextbox } from '../../classes/questions/question-textbox';
import { Unsubscribe } from '../../classes/unsubscribe';
import { NOT_NUMBERS } from '../../constants/regex.constants';
import { VALIDATION_CODE_LENGTH } from '../../constants/registration.constants';
import { Direction } from '../../enum/app.enum';
import { QuestionAppearance } from '../../enum/question.enum';
import { RegistrationType } from '../../enum/registration.enum';
import { AppState } from '../../models/ngxs/app.models';
import { QuestionDataHandlerConfig } from '../../models/registration.models';
import { GenerateValidateCodeQuestionsConfig } from '../../models/widgets/validate-code-widget.models';
import { getQuestion } from '../../utils/questions/questions.utils';
import { getQueryParamValue } from '../../utils/url.utils';

@Component({
  selector: 'rs-accounts-validate-code-widget',
  templateUrl: './validate-code-widget.component.html',
  styleUrls: ['./validate-code-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ValidateCodeWidgetComponent extends Unsubscribe implements OnInit, OnDestroy {
  @Select((state: AppState) => state.root.customCopyPastedData) customCopyPastedData$: Observable<string>;
  @Select((state: AppState) => state.registration.isInvalidValidationCode) isInvalidValidationCode$: Observable<boolean>;

  public readonly Direction: typeof Direction = Direction;
  public readonly RegistrationType: typeof RegistrationType = RegistrationType;

  public registrationType: RegistrationType;

  public questions: QuestionBase[] = [];

  constructor(
    private readonly _store: Store,
    private readonly _route: ActivatedRoute,
    private readonly _changeDetectorRef: ChangeDetectorRef,
  ) {
    super();
  }

  ngOnInit(): void {
    this._initSubscriptions();
  }

  public handleResendCode(): void {
    this._store.dispatch(
      new SendValidationCode(
        this.registrationType,
        getRegistrationTypeData(this.registrationType),
        null,
        true,
      ),
    );
  }

  private _initSubscriptions(): void {
    this.subscribeTo = this._route.params
      .subscribe(() => this._generateValidateCodeQuestions());

    this.subscribeTo = this.customCopyPastedData$
      .subscribe((customCopyPastedData: string) => {
        if (customCopyPastedData) {
          this._generateValidateCodeQuestions({ pastedValidationCode: customCopyPastedData });
        }
      });

    this.subscribeTo = this.isInvalidValidationCode$
      .subscribe((isInvalidValidationCode: boolean) => {
        if (isInvalidValidationCode) {
          this._generateValidateCodeQuestions({ isInvalidValidationCode });

          this._store.dispatch(new SetInvalidValidationCodeFlag(false));
        }
      });
  }

  private _generateValidateCodeQuestions(
    {
      pastedValidationCode,
      isInvalidValidationCode,
    }: GenerateValidateCodeQuestionsConfig = {},
  ): void {
    this.registrationType = getRegistrationType(this._route);

    const validationCode = this._getValidationCodeFromAdditionalData(
      getQueryParamValue('verification_code'),
      pastedValidationCode,
    );
    this.questions = [
      new QuestionTextbox({
        key: 'digit1',
        appearance: QuestionAppearance.None,
        initialValue: this._getInitialValue(validationCode, isInvalidValidationCode, 0),
        focus: this._hasQuestionFocus(validationCode, isInvalidValidationCode, 0),
        isCustomCopyPaste: true,
        constraints: {
          maxLength: 1,
          pattern: NOT_NUMBERS,
        },
        keyButtons: ['Backspace'],

        onFocus: () => this._handleFocus('digit1'),

        onKeyButtonPress: (pressedButtonKey: string) =>
          this._handleKeyButtonPress('digit1', pressedButtonKey),

        onValueChange: (value: string, questions: QuestionBase[]) =>
          this._handleValueChange(questions),
      }),
      new QuestionTextbox({
        key: 'digit2',
        appearance: QuestionAppearance.None,
        initialValue: this._getInitialValue(validationCode, isInvalidValidationCode, 1),
        focus: this._hasQuestionFocus(validationCode, isInvalidValidationCode, 1),
        isCustomCopyPaste: true,
        constraints: {
          maxLength: 1,
          pattern: NOT_NUMBERS,
        },
        keyButtons: ['Backspace'],

        onFocus: () => this._handleFocus('digit2'),

        onKeyButtonPress: (pressedButtonKey: string) =>
          this._handleKeyButtonPress('digit2', pressedButtonKey),

        onValueChange: (value: string, questions: QuestionBase[]) =>
          this._handleValueChange(questions),
      }),
      new QuestionTextbox({
        key: 'digit3',
        appearance: QuestionAppearance.None,
        initialValue: this._getInitialValue(validationCode, isInvalidValidationCode, 2),
        focus: this._hasQuestionFocus(validationCode, isInvalidValidationCode, 2),
        isCustomCopyPaste: true,
        constraints: {
          maxLength: 1,
          pattern: NOT_NUMBERS,
        },
        keyButtons: ['Backspace'],

        onFocus: () => this._handleFocus('digit3'),

        onKeyButtonPress: (pressedButtonKey: string) =>
          this._handleKeyButtonPress('digit3', pressedButtonKey),

        onValueChange: (value: string, questions: QuestionBase[]) =>
          this._handleValueChange(questions),
      }),
      new QuestionTextbox({
        key: 'digit4',
        appearance: QuestionAppearance.None,
        initialValue: this._getInitialValue(validationCode, isInvalidValidationCode, 3),
        focus: this._hasQuestionFocus(validationCode, isInvalidValidationCode, 3),
        isCustomCopyPaste: true,
        constraints: {
          maxLength: 1,
          pattern: NOT_NUMBERS,
        },
        keyButtons: ['Backspace'],

        onFocus: () => this._handleFocus('digit4'),

        onKeyButtonPress: (pressedButtonKey: string) =>
          this._handleKeyButtonPress('digit4', pressedButtonKey),

        onValueChange: (value: string, questions: QuestionBase[]) =>
          this._handleValueChange(questions),
      }),
      new QuestionTextbox({
        key: 'digit5',
        appearance: QuestionAppearance.None,
        initialValue: this._getInitialValue(validationCode, isInvalidValidationCode, 4),
        focus: this._hasQuestionFocus(validationCode, isInvalidValidationCode, 4),
        isCustomCopyPaste: true,
        constraints: {
          maxLength: 1,
          pattern: NOT_NUMBERS,
        },
        keyButtons: ['Backspace'],

        onFocus: () => this._handleFocus('digit5'),
        
        onKeyButtonPress: (pressedButtonKey: string) =>
          this._handleKeyButtonPress('digit5', pressedButtonKey),

        onValueChange: (value: string, questions: QuestionBase[]) =>
          this._handleValueChange(questions),
      }),
      new QuestionTextbox({
        key: 'digit6',
        appearance: QuestionAppearance.None,
        initialValue: this._getInitialValue(validationCode, isInvalidValidationCode, 5),
        initialValueChange: true,
        focus: this._hasQuestionFocus(validationCode, isInvalidValidationCode, 5),
        isCustomCopyPaste: true,
        constraints: {
          maxLength: 1,
          pattern: NOT_NUMBERS,
        },
        keyButtons: ['Backspace'],

        onFocus: () => this._handleFocus('digit6'),

        onKeyButtonPress: (pressedButtonKey: string) =>
          this._handleKeyButtonPress('digit6', pressedButtonKey),

        onValueChange: (value: string, questions: QuestionBase[]) =>
          this._handleValueChange(questions),
      }),
    ];

    this._changeDetectorRef.detectChanges();
  }

  private _getInitialValue(
    validationCode: string,
    isInvalidValidationCode: boolean,
    validationCodeSymbolPosition: number
  ): string {
    if (isInvalidValidationCode || !validationCode) {
      return '';
    }

    return validationCode[validationCodeSymbolPosition];
  }

  private _handleFocus(currentQuestionKey: string): void {
    const emptyQuestion = this.questions.find(question => !question.getValue());

    let hasCurrentQuestionFocus;

    if (emptyQuestion) {
      hasCurrentQuestionFocus = emptyQuestion.key === currentQuestionKey;
    } else {
      hasCurrentQuestionFocus = !(this._getQuestionsValidationCode(this.questions).length === VALIDATION_CODE_LENGTH);
    }

    if (!hasCurrentQuestionFocus) {
      getQuestion<QuestionTextbox>(currentQuestionKey, this.questions).setFocus(false);
      
      (this.questions.find(question => !question.getValue()) as QuestionTextbox)?.setFocus(true);

      this.questions = cloneDeep(this.questions);
    }
  }

  private _handleKeyButtonPress(
    currentQuestionKey: string,
    pressedButtonKey: string,
  ): void {
    if (pressedButtonKey === 'Backspace' && currentQuestionKey !== 'digit1') {
      this._switchQuestionFocus({
        currentQuestionKey,
        questions: cloneDeep(this.questions),
        isBackspaceButton: true,
      });
    }
  }

  private _handleValueChange(questions: QuestionBase[]): void {
    const validationCode = this._getQuestionsValidationCode(questions);

    if (validationCode.length === VALIDATION_CODE_LENGTH) {
      (questions as QuestionTextbox[]).forEach((question: QuestionTextbox) =>
        question.setFocus(false),
      );

      this.questions = cloneDeep(questions);

      this._store.dispatch(
        new ValidateCode(
          this.registrationType,
          getRegistrationTypeData(this.registrationType),
          validationCode,
        ),
      );
    } else {
      this._switchQuestionFocus({ questions });
    }
  }

  private _hasQuestionFocus(
    validationCode: string,
    isInvalidValidationCode: boolean,
    validationCodeSymbolPosition: number,
  ): boolean {
    if (isInvalidValidationCode && validationCodeSymbolPosition === 0) {
      return true;
    } else if (isInvalidValidationCode && validationCodeSymbolPosition !== 0) {
      return false;
    }

    if (validationCode?.length === VALIDATION_CODE_LENGTH) {
      return false;
    }

    return validationCode?.length
      ? validationCode.length === validationCodeSymbolPosition
      : validationCodeSymbolPosition === 0;
  }

  private _switchQuestionFocus(
    {
      questions = [],
      currentQuestionKey = '',
      isBackspaceButton = false,
    }: QuestionDataHandlerConfig = {},
  ): void {
    const nextQuestionKey = this._getNextQuestionKey({
      questions,
      currentQuestionKey,
      isBackspaceButton,
    });

    if (isBackspaceButton) {
      this._switchBackspaceButtonPressFocus(
        currentQuestionKey,
        nextQuestionKey,
        questions,
      );
    } else {
      this._switchValueChangeFocus(nextQuestionKey, cloneDeep(questions));
    }
  }

  private _switchBackspaceButtonPressFocus(
    currentQuestionKey: string,
    nextQuestionKey: string,
    questions: QuestionBase[],
  ): void {
    getQuestion<QuestionTextbox>(currentQuestionKey, questions).setFocus(false);

    getQuestion<QuestionTextbox>(nextQuestionKey, questions).setFocus(true);
    getQuestion<QuestionTextbox>(nextQuestionKey, questions).setValue('');

    this.questions = questions;
  }

  private _switchValueChangeFocus(
    nextQuestionKey: string,
    questions: QuestionBase[],
  ): void {
    (questions as QuestionTextbox[]).forEach((question: QuestionTextbox) => {
      if (question.key === nextQuestionKey) {
        question.setFocus(true);
      } else {
        question.setFocus(false);
      }
    });

    this.questions = questions;
  }

  private _getNextQuestionKey(
    {
      questions = [],
      currentQuestionKey = '',
      isBackspaceButton = false,
    }: QuestionDataHandlerConfig = {},
  ): string {
    return isBackspaceButton
      ? `digit${+currentQuestionKey.slice(-1) - 1}`
      : questions.find(question => !question.getValue()).key;
  }

  private _getQuestionsValidationCode(questions: QuestionBase[]): string {
    return questions
      .map(question => getQuestion<QuestionTextbox>(question.key, questions).getValue())
      .join('');
  }

  private _getValidationCodeFromAdditionalData(
    queryParamValidationCode: string,
    pastedValidationCode: string,
  ): string {
    if (queryParamValidationCode) {
      return pastedValidationCode
        ? queryParamValidationCode + pastedValidationCode.slice(0, VALIDATION_CODE_LENGTH - queryParamValidationCode.length)
        : queryParamValidationCode;
    }

    if (pastedValidationCode) {
      const questionsValidationCode = this._getQuestionsValidationCode(this.questions);

      return questionsValidationCode.length === VALIDATION_CODE_LENGTH
        ? questionsValidationCode
        : questionsValidationCode + pastedValidationCode.slice(0, VALIDATION_CODE_LENGTH - questionsValidationCode.length);
    }

    return null;
  }
}
