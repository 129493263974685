import { NgModule } from '@angular/core';

import { FormModule } from '../shared/components/form/form.module';
import { SharedModule } from '../shared/modules/shared.module';
import { IntlTelPhoneWidgetModule } from '../shared/widgets/intl-tel-phone-widget/intl-tel-phone-widget.module';
import { PasswordStrengthWidgetModule } from '../shared/widgets/password-strength-widget/password-strength-widget.module';
import { ValidateCodeWidgetModule } from '../shared/widgets/validate-code-widget/validate-code-widget.module';
import { ButtonModule } from '../shared/components/button/button.module';

import { CreatePasswordComponent } from './create-password/create-password.component';
import { RegistrationRoutingModule } from './registration-routing.module';
import { SignUpComponent } from './sign-up/sign-up.component';
import { ValidateCodeComponent } from './validate-code/validate-code.component';
import { ValidateDataComponent } from './validate-data/validate-data.component';
import {
  SuccessfullCodeValidationComponent,
} from './validate-code/successfull-code-validation/successfull-code-validation.component';

@NgModule({
  imports: [
    SharedModule,
    RegistrationRoutingModule,
    FormModule,
    PasswordStrengthWidgetModule,
    ValidateCodeWidgetModule,
    IntlTelPhoneWidgetModule,
    ButtonModule,
  ],
  declarations: [
    CreatePasswordComponent,
    ValidateCodeComponent,
    ValidateDataComponent,
    SignUpComponent,
    SuccessfullCodeValidationComponent,
  ],
  exports: [
    RegistrationRoutingModule,
    CreatePasswordComponent,
  ],
})
export class RegistrationModule {}
