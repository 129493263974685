import { AbstractControl, ValidatorFn } from '@angular/forms';

import { QuestionType } from '../../enum/question.enum';
import { QuestionBaseConstraints } from '../../models/questions/question-base.models';

export interface IQuestionBase<T = string> {
  key: string;
  controlType?: QuestionType;
  label?: string;
  initialValue?: T;
  validators?: ValidatorFn[];
  constraints?: QuestionBaseConstraints;
  initialValueChange?: boolean;

  onValueChange?: (value: T, questions: QuestionBase[]) => void;
}

export class QuestionBase<T = any> implements IQuestionBase<T> {
  public key: string;
  public controlType?: QuestionType;
  public label?: string;
  public initialValue?: T;
  public validators?: ValidatorFn[];
  public constraints?: QuestionBaseConstraints;
  public initialValueChange?: boolean;

  public onValueChange?: (value: T, questions: QuestionBase[]) => void;

  private _formControl?: AbstractControl;

  constructor(options: IQuestionBase<T>) {
    this.key = options.key || '';
    this.controlType = options.controlType;
    this.label = options.label || '';
    this.initialValue = options.initialValue;
    this.validators = options.validators || [];
    this.constraints = options.constraints;
    this.initialValueChange = options.initialValueChange;

    this.onValueChange = options.onValueChange || (() => null);
  }

  public getFormControl(): AbstractControl {
    return this._formControl;
  }

  public setFormControl(formControl: AbstractControl): void {
    this._formControl = formControl;
  }

  public getValue(): T {
    return this._formControl.value;
  }

  public setValue(value: T): void {
    this._formControl.setValue(value);
  }
}