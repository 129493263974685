import { NgModule } from '@angular/core';

import { FormModule } from '../../components/form/form.module';
import { SharedModule } from '../../modules/shared.module';

import { ValidateCodeWidgetComponent } from './validate-code-widget.component';

@NgModule({
  imports: [
    SharedModule,
    FormModule,
  ],
  declarations: [
    ValidateCodeWidgetComponent,
  ],
  exports: [
    ValidateCodeWidgetComponent,
  ],
})
export class ValidateCodeWidgetModule {}
