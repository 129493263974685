<div class="rs-password-strength">
  <div class="rs-password-strength__row strength">
    <span [ngClass]="classes">
      {{ strengthLabel | translate | uppercase }}
    </span>
    <mat-progress-bar
      mode="determinate"
      [ngClass]="classes"
      [value]="strengthValue">
    </mat-progress-bar>
  </div>
  <div class="rs-password-strength__row checks">
    <div
      *ngFor="let passwordCheck of passwordChecks"
      class="password-check"
    >
      <mat-icon
        *ngIf="passwordCheck.isChecked"
        [class.checked]="passwordCheck.isChecked"
      >
        check_circle
      </mat-icon>
      <mat-icon *ngIf="!passwordCheck.isChecked">check_circle_outline</mat-icon>
      <span>{{ passwordCheck.label  | translate }}</span>
    </div>
  </div>
</div>
