import { NgModule } from '@angular/core';

import { ButtonModule } from '../../shared/components/button/button.module';
import { FormModule } from '../../shared/components/form/form.module';
import { SharedModule } from '../../shared/modules/shared.module';

import { LoginComponent } from './login.component';
import { WarningComponent } from './warning/warning.component';

@NgModule({
  imports: [
    SharedModule,
    FormModule,
    ButtonModule,
  ],
  declarations: [
    LoginComponent,
    WarningComponent,
  ],
  exports: [
    LoginComponent,
  ],
})
export class LoginModule {}
