import { QuestionType } from '../../enum/question.enum';
import { QuestionCustomTemplateContext } from '../../models/questions/question-custom.models';

import { IQuestionBase, QuestionBase } from './question-base';

export interface IQuestionCustom<T extends QuestionCustomTemplateContext> extends IQuestionBase<T> {
  templateContext?: T;
}

export class QuestionCustom<T extends QuestionCustomTemplateContext> extends QuestionBase<T> implements IQuestionCustom<T> {
  private _templateContext: T;

  constructor(options: IQuestionCustom<T>) {
    super({ ...options, controlType: QuestionType.Custom });

    this.controlType = QuestionType.Custom;
    this._templateContext = options.templateContext;
  }

  public getTemplateContext(): T {
    return this._templateContext;
  }

  public setTemplateContext(templateContext: T): void {
    this._templateContext = templateContext;
  }
}
