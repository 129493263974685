import { Component, Input } from '@angular/core';

import { fade } from 'src/app/shared/animations/animations';

@Component({
  selector: 'rs-accounts-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
  animations: [
    fade,
  ]
})
export class SpinnerComponent {
  @Input()
  isSpinner: boolean;
}
