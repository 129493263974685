import { SocialLoginType } from 'src/app/shared/enum/auth.enum';
import { LoginData, RecoverPasswordData } from 'src/app/shared/models/auth.models';

export class Login {
  public static type = '[Auth] Login';

  constructor(
    public readonly data: LoginData,
  ) {
  }
}

export class LoginSocial {
  public static type = '[Auth] Social';

  constructor(
    public readonly socialLoginType: SocialLoginType,
  ) {
  }
}

export class RecoverPassword {
  public static type = '[Auth] Recover Password';

  constructor(
    public readonly data: RecoverPasswordData,
  ) {
  }
}
