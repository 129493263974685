import { Directive, ElementRef, Input, OnInit } from '@angular/core';

@Directive({
  selector: '[m]',
})
export class MarginDirective implements OnInit {
  @Input()
  m: number[];

  constructor(
    private readonly _elementRef: ElementRef,
  ) {
  }

  ngOnInit(): void {
    switch (this.m.length) {
      case 1: {
        this.setMargin(this.m[0], this.m[0], this.m[0], this.m[0]);

        break;
      }
      case 2: {
        this.setMargin(this.m[0], this.m[1], this.m[0], this.m[1]);

        break;
      }
      case 3: {
        this.setMargin(this.m[0], this.m[1], this.m[2], this.m[1]);
        
        break;
      }
      case 4: {
        this.setMargin(this.m[0], this.m[1], this.m[2], this.m[3]);

        break;
      }
    }
  }

  private setMargin(
    top: number,
    right: number,
    bottom: number,
    left: number,
  ): void {
    this._elementRef.nativeElement.style.marginTop = `${top}px`;
    this._elementRef.nativeElement.style.marginRight = `${right}px`;
    this._elementRef.nativeElement.style.marginBottom = `${bottom}px`;
    this._elementRef.nativeElement.style.marginLeft = `${left}px`;
  }
}
