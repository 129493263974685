import { NgModule } from '@angular/core';

import { DirectivesModule } from 'src/app/shared/modules/directives.module';
import { SharedModule } from 'src/app/shared/modules/shared.module';

import { TextboxComponent } from './textbox.component';

@NgModule({
  imports: [
    SharedModule,
    DirectivesModule,
  ],
  declarations: [
    TextboxComponent,
  ],
  exports: [
    TextboxComponent,
  ],
})
export class TextboxModule {}
