import { EventEmitter } from '@angular/core';
import { Component, Input, Output } from '@angular/core';

@Component({
  selector: 'rs-accounts-warning',
  templateUrl: './warning.component.html',
  styleUrls: ['./warning.component.scss'],
})
export class WarningComponent {
  @Input()
  warningKey: string;

  @Output()
  onForgotPasswordClick: EventEmitter<void> = new EventEmitter();

  public handleForgotPasswordClick(): void {
    this.onForgotPasswordClick.emit();
  }
}
