import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';

import { CookieService } from 'ngx-cookie-service';

import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { Token } from 'src/app/shared/models/app.models';
import { isSwitchAppUrl } from 'src/app/shared/utils/user.utils';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class CookieHandlerInterceptor implements HttpInterceptor {
  constructor(
    private readonly _cookieService: CookieService,
  ) {
  }

  public intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse && isSwitchAppUrl(request.url)) {
          this._cookieService.set(
            `${environment.prefix}refreshToken`,
            (event as HttpResponse<Token>).body.refreshToken,
            31536000,
            null,
            environment.rockspoonDomain,
          );
        }
      }),
    );
  }
}
