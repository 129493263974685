export enum Position {
  Left,
  Center,
  Right,
}

export enum Direction {
  Row = 'row',
  Column = 'column',
}

export enum UrlDataType {
  Param = 'paramMap',
  Query = 'queryParamMap',
}

export enum InputType {
  Text = 'text',
  Password = 'password',
}
