import { QuestionType } from '../../enum/question.enum';

import { IQuestionBase, QuestionBase } from './question-base';

export interface IQuestionCheckbox<T> extends IQuestionBase<T> {
  innerHtml?: string;
}

export class QuestionCheckbox<T = boolean> extends QuestionBase<T> implements IQuestionCheckbox<T> {
  public innerHtml: string;

  constructor(options: IQuestionCheckbox<T>) {
    super({ ...options, controlType: QuestionType.Checkbox });

    this.innerHtml = options.innerHtml;
  }
}
