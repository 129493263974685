<div class="rs-login">
  <rs-accounts-warning
    *ngIf="warningKey"
    [warningKey]="warningKey"
    (onForgotPasswordClick)="handleForgotPasswordClick()"
  >
  </rs-accounts-warning>
  <rs-accounts-form
    actionLabel="actions.login"
    width="460px"
    [questions]="questions"
    [actionMargin]="[20, 0, 0]"
    [isUpperCaseAction]="true"
    (onSubmit)="handleLoginClick($event)"
  >
  </rs-accounts-form>
  <div class="rs-login__row forgot-password">
    <span (click)="handleForgotPasswordClick()">{{ 'login.forgotPassword' | translate }}</span>
    <span>{{ 'login.or' | translate }}</span>
  </div>
  <div class="rs-login__row social-login">
    <rs-accounts-button
      label="actions.continueFacebook"
      color="blue"
      [margin]="[20, 0, 0]"
      [isUpperCase]="true"
      [withIcon]="true"
      (onClick)="handleSocialLoginClick(SocialLoginType.Facebook)">
    </rs-accounts-button>
    <rs-accounts-button
      label="actions.continueGoogle"
      color="blue"
      [margin]="[20, 0, 0]"
      [isUpperCase]="true"
      [withIcon]="true"
      (onClick)="handleSocialLoginClick(SocialLoginType.Google)">
    </rs-accounts-button>
  </div>
  <div class="rs-login__row signup">
    <span>{{ 'login.noAccount' | translate }}</span>
    <rs-accounts-button
      label="actions.signup"
      color="blue"
      [margin]="[20, 0, 0]"
      [isUpperCase]="true"
      (onClick)="handleSignupButtonClick()">
    </rs-accounts-button>
  </div>
</div>
