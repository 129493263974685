<!-- Translation is not added as the page can be not inside the account project -->

<div class="rs-congrats-page">
  <div class="header">
    <span class="title">Congratulations!</span>
  </div>
  <div class="image">
    <img
      class="logo"
      [src]="userInfo.image.src"
      [alt]="userInfo.image.alt"
    />
  </div>
  <div class="date">
    <div class="calendar-icon">
      <mat-icon>calendar_today</mat-icon>
    </div>
    <div class="date-info">
      <span>{{ userInfo.date | date : 'EEEE, MMMM d y' }}</span>
      <span class="delimiter">at</span>
      <span>{{ userInfo.date | date : 'h:mm a' }}</span>
    </div>
  </div>
  <div class="info">
    <span>
      <span class="name">{{ userInfo.name }}</span>
      <span class="text">from Rockspoon has been assigned to help you with your enrolment process.</span>
    </span>
  </div>
  <div class="zoom">
    <a
      class="link"
      [href]="userInfo.zoomLink"
    >
      {{ userInfo.zoomLink }}
    </a>
    <span class="passcode">Passcode: {{ userInfo.zoomPasscode }}</span>
  </div>
</div>
