import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { Map } from 'src/app/shared/models/app.models';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class ApiService {
  private _apiUrl: string;

  constructor(
    private readonly _httpClient: HttpClient,
  ) {
    this._apiUrl = environment.apiUrl;
  }

  public getApiUrl(): string {
    return this._apiUrl;
  }

  public get<T>(
    url: string,
    headers?: Map,
  ): Observable<T> {
    return this._httpClient.get<T>(`${this._apiUrl}/${url}`, { headers });
  }

  public post<T, K>(
    url: string,
    data: T,
  ): Observable<K> {
    return this._httpClient.post<K>(`${this._apiUrl}/${url}`, data);
  }

  public put<T, K>(
    url: string,
    data: T | null = null,
  ): Observable<K> {
    return this._httpClient.put<K>(`${this._apiUrl}/${url}`, data);
  }
}
