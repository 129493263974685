import { Injectable } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';

import { QuestionBase } from '../classes/questions/question-base';
import { QuestionCustom } from '../classes/questions/question-custom';

@Injectable({ providedIn: 'root' })
export class QuestionControlService {
  constructor(
    private readonly _formBuilder: FormBuilder,
  ) {
  }

  public generateForm(questions: QuestionBase[]): FormGroup {
    const formControls: { [key: string]: FormControl } = {};

    questions.forEach(question => {
      const formControl = new FormControl(
        question.initialValue || '',
        {
          validators: question.validators || null,
        },
      );

      formControls[question.key] = formControl;

      question.setFormControl(formControl);

      if (question instanceof QuestionCustom) {
        question.setTemplateContext({
          ...question.getTemplateContext(),
          control: formControl,
        });
      }

      formControl.setErrors({ initial: true });

      formControl.valueChanges.subscribe(value => {
        const questionPattern = question.constraints?.pattern;

        if (questionPattern && !value.match(questionPattern)) {
          question.onValueChange(value, questions);
        } else if (!questionPattern) {
          question.onValueChange(value, questions);
        }
      });

      if (question.initialValueChange) {
        setTimeout(() => question.onValueChange(question.initialValue, questions));
      }
    });

    return this._formBuilder.group(formControls);
  }
}
