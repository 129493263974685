import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, TemplateRef } from '@angular/core';
import { FormGroup, ValidatorFn } from '@angular/forms';

import { QuestionBase } from '../../classes/questions/question-base';
import { Direction } from '../../enum/app.enum';
import { ButtonType } from '../../enum/button.enum';
import { QuestionControlService } from '../../services/question-control.service';

@Component({
  selector: 'rs-accounts-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss'],
  providers: [QuestionControlService],
})
export class FormComponent implements OnInit, OnChanges {
  @Input()
  questions: QuestionBase<string>[] = [];

  @Input()
  direction: Direction = Direction.Column;

  @Input()
  withAction: boolean = true;

  @Input()
  actionLabel: string;

  @Input()
  isUpperCaseAction: boolean;
  
  @Input()
  actionMargin: number[];

  @Input()
  width: string;

  @Input()
  customQuestion: TemplateRef<any>;

  @Input()
  formValidators: ValidatorFn[];

  @Output()
  onSubmit: EventEmitter<any> = new EventEmitter<any>();

  public readonly ButtonType: typeof ButtonType = ButtonType;

  public formGroup: FormGroup;

  public classes: any;
  public styles: any;

  constructor(
    private readonly _questionControlService: QuestionControlService,
  ) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    const questions = changes.questions?.currentValue as QuestionBase[];
    const isFormNotInitialized = questions && !questions.some(question => question.getFormControl());

    if (isFormNotInitialized) {
      this._initializeForm();
    }
  }

  ngOnInit(): void {
    this.formGroup = this._questionControlService.generateForm(this.questions);

    this.classes = {
      [this.direction]: !!this.direction,
    };

    this.formGroup.setValidators(this.formValidators);
  }

  public handleSubmit(): void {
    if (this.formGroup.valid) {
      this.onSubmit.emit(this.formGroup.value);
    }
  }

  private _initializeForm(): void {
    this.formGroup = this._questionControlService.generateForm(this.questions);

    this.classes = {
      [this.direction]: !!this.direction,
    };

    this.formGroup.setValidators(this.formValidators);
  }
}
