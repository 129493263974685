import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

import { Action, State, StateContext, Store } from '@ngxs/store';

import { EMPTY, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import { Client } from 'src/app/shared/enum/user.enum';
import { ShowSpinner } from 'src/app/core/ngxs/root/root.actions';
import { RegistrationService } from 'src/app/core/services/registration.service';
import {
  INVALID_VALIDATION_CODE_API_CODE,
  REGISTERED_USER_API_CODE,
} from 'src/app/shared/constants/registration.constants';
import { RegistrationType } from 'src/app/shared/enum/registration.enum';
import { RegistrationStateModel } from 'src/app/shared/models/ngxs/registration.models';
import { getQueryParamValue } from 'src/app/shared/utils/url.utils';

import { UserService } from '../../services/user.service';
import { NotificationService } from '../../services/notification.service';
import { RouterService } from '../../services/router.service';

import {
  CreatePassword,
  SendValidationCode,
  SetInvalidValidationCodeFlag,
  ValidateCode,
} from './registration.actions';

@State<RegistrationStateModel>({
  name: 'registration',
  defaults: {
    pastedValidationCode: null,
    isInvalidValidationCode: false,
  }
})
@Injectable()
export class RegistrationState {
  constructor(
    private readonly _store: Store,
    private readonly _routerService: RouterService,
    private readonly _registrationService: RegistrationService,
    private readonly _userService: UserService,
    private readonly _notificationService: NotificationService,
    private readonly _translateService: TranslateService,
  ) {
  }

  @Action(SendValidationCode)
  sendValidationCode(
    context: StateContext<void>,
    {
      data,
      registrationType,
      queryParams,
      isResend,
    }: SendValidationCode,
  ) {
    this._store.dispatch(new ShowSpinner(true));

    return this._registrationService.sendValidationCode(registrationType, data)
      .subscribe(
        () => {
          this._store.dispatch(new ShowSpinner(false));

          if (isResend) {
            this._notificationService.showSuccessMessage(
              this._translateService.instant(`registration.validateCode.resend.${registrationType}`),
            );
          } else {
            const url = getQueryParamValue('hasRegisterPath')
              ? `/registration/validate-code/register/${registrationType}`
              : `/registration/validate-code/${registrationType}`;

            this._routerService.navigate(
              url,
              {
                ...queryParams,
                [registrationType]: btoa(data),
              },
              ['hasRegisterPath'],
            );
          }
        },
        (({ error }: HttpErrorResponse) => {
          if (error.code === REGISTERED_USER_API_CODE) {
            this._routerService.navigate(
              '/login',
              { isRegistration: true },
            );
          }
        }),
      );
  }

  @Action(ValidateCode)
  validateCode(
    context: StateContext<void>,
    { registrationType, data, code }: ValidateCode,
  ) {
    this._store.dispatch(new ShowSpinner(true));

    return this._registrationService.validateCode(registrationType, data, code)
      .pipe(
        tap(validateCodeResponse => {
          const cellphone = getQueryParamValue('cellphone');
          const hasRegisterPath = this._routerService.hasPathPart('/register/');

          if (hasRegisterPath) {
            if (registrationType === RegistrationType.Email || registrationType === RegistrationType.Cellphone) {
              const validateDataCodeIdKey = registrationType === RegistrationType.Email
                ? 'emailValidation'
                : 'phoneValidation';

              this._store.dispatch(new ShowSpinner(false));

              this._routerService.navigate(
                '/registration/create-password',
                { [validateDataCodeIdKey]: validateCodeResponse.id },
              );
            } else {
              this._store.dispatch(
                new SendValidationCode(
                  RegistrationType.Cellphone,
                  cellphone.startsWith('+') ? cellphone : `+${cellphone}`,
                  { emailValidation: validateCodeResponse.id },
                ),
              );
            }
          } else {
            if (registrationType === RegistrationType.Email || registrationType === RegistrationType.Cellphone) {
              this._store.dispatch(new ShowSpinner(false));

              this._routerService.navigate(`/registration/validate-code/${registrationType}/success`);
            } else {
              this._store.dispatch(
                new SendValidationCode(
                  RegistrationType.Cellphone,
                  cellphone.startsWith('+') ? cellphone : `+${cellphone}`,
                  { emailValidation: validateCodeResponse.id },
                ),
              );
            }
          }
        }),
        catchError((err: HttpErrorResponse) => {
          if (err.error.code === INVALID_VALIDATION_CODE_API_CODE) {
            this._notificationService.showErrorMessage(
              this._translateService.instant('registration.validateCode.invalidValidationCodeErrorMessage'),
            );

            this._store.dispatch(new SetInvalidValidationCodeFlag(true));
          }

          return of();
        }),
      );
  }

  @Action(CreatePassword)
  createPassword(
    context: StateContext<void>,
    { data, isPasswordRecovery }: CreatePassword,
  ) {
    this._store.dispatch(new ShowSpinner(true));

    return this._registrationService.createPassword(data, isPasswordRecovery)
      .pipe(
        switchMap(({ accessToken }) => {
          const client = getQueryParamValue('client');

          if (!client?.trim()) {
            this._store.dispatch(new ShowSpinner(false));

            this._routerService.navigate('/login');

            return EMPTY;
          }

          return this._userService.fetchClientUrls(accessToken)
            .pipe(
              map(clientUrlsResponse => {
                this._store.dispatch(new ShowSpinner(false));

                location.href = `https://${clientUrlsResponse[client as unknown as Client]}`;
              }),
            );
        }),
      );
  }

  @Action(SetInvalidValidationCodeFlag)
  setInvalidValidationCodeFlag(
    { patchState }: StateContext<RegistrationStateModel>,
    { isInvalidValidationCode }: SetInvalidValidationCodeFlag,
  ) {
    patchState({ isInvalidValidationCode });
  }
}
