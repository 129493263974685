import { Component, Input, OnChanges } from '@angular/core';

import { Option } from 'src/app/shared/models/button.models';

import { MIN_PASSWORD_LENGTH, PASSWORD_CHECKS } from '../../constants/password.constants';
import {
  LETTERS,
  LOWER_CASE_LETTERS,
  NUMBERS, SPECIAL_SYMBOLS,
  UPPER_CASE_LETTERS,
} from '../../constants/regex.constants';
import {
  PasswordCheck,
  PasswordCheckCount,
  PasswordStrengthColor,
  PasswordStrengthLabel,
  PasswordStrengthValue,
} from '../../enum/registration.enum';

@Component({
  selector: 'rs-accounts-password-strength-widget',
  templateUrl: './password-strength-widget.component.html',
  styleUrls: ['./password-strength-widget.component.scss'],
})
export class PasswordStrengthWidgetComponent implements OnChanges {
  @Input()
  password: string;

  public passwordChecks = PASSWORD_CHECKS;
  public strengthValue: number;
  public strengthLabel: string;

  public classes: any;

  ngOnChanges(): void {
    this.passwordChecks = PASSWORD_CHECKS.map(check => ({
      ...check,
      isChecked: this._isPasswordCheckPassed(this.password, check.key as PasswordCheck),
    }));
    this.strengthValue = this._getPasswordStrength(this.passwordChecks);
    this.strengthLabel = this._getPasswordStrengthLabel(this.strengthValue);
    this.classes = { [this._getPasswordStrengthLabelColor(this.strengthValue)]: true };
  }

  private _getPasswordStrengthLabelColor(strengthValue: PasswordStrengthValue): string {
    switch (strengthValue) {
      case PasswordStrengthValue.Weak: {
        return PasswordStrengthColor.Weak;
      }
      case PasswordStrengthValue.Moderate: {
        return PasswordStrengthColor.Moderate;
      }
      case PasswordStrengthValue.Strong: {
        return PasswordStrengthColor.Strong;
      }
      case PasswordStrengthValue.VeryStrong: {
        return PasswordStrengthColor.VeryStrong;
      }
      default: {
        return PasswordStrengthColor.Weak;
      }
    }
  }

  private _isPasswordCheckPassed(
    password: string,
    check: PasswordCheck,
  ): boolean {
    switch (check) {
      case PasswordCheck.MinCharacters: {
        return !!password && !(password.length < MIN_PASSWORD_LENGTH);
      }
      case PasswordCheck.MinCharacter: {
        return !!password && !!(password.match(LETTERS));
      }
      case PasswordCheck.MinLowerCaseCharacter: {
        return !!password && !!(password.match(LOWER_CASE_LETTERS));
      }
      case PasswordCheck.MinUpperCaseCharacter: {
        return !!password && !!(password.match(UPPER_CASE_LETTERS));
      }
      case PasswordCheck.MinNumber: {
        return !!password && !!(password.match(NUMBERS));
      }
      case PasswordCheck.MinSymbol: {
        return !!password && !!(password.match(SPECIAL_SYMBOLS));
      }
    }
  }

  private _getPasswordStrength(passwordChecks: Option[]): number {
    const passedCheckCount = passwordChecks.reduce(
      (count, check) => count + (check.isChecked ? 1 : 0),
      0,
    );

    switch (passedCheckCount) {
      case PasswordCheckCount.One:
      case PasswordCheckCount.Two: {
        return PasswordStrengthValue.Weak;
      }
      case PasswordCheckCount.Three:
      case PasswordCheckCount.Four: {
        return PasswordStrengthValue.Moderate;
      }
      case PasswordCheckCount.Five: {
        return PasswordStrengthValue.Strong;
      }
      case PasswordCheckCount.Six: {
        return PasswordStrengthValue.VeryStrong;
      }
      default: {
        return PasswordStrengthValue.None;
      }
    }
  }

  private _getPasswordStrengthLabel(strengthValue: number): PasswordStrengthLabel {
    switch (strengthValue) {
      case PasswordStrengthValue.Weak: {
        return PasswordStrengthLabel.Weak;
      }
      case PasswordStrengthValue.Moderate: {
        return PasswordStrengthLabel.Moderate;
      }
      case PasswordStrengthValue.Strong: {
        return PasswordStrengthLabel.Strong;
      }
      case PasswordStrengthValue.VeryStrong: {
        return PasswordStrengthLabel.VeryStrong;
      }
      default: {
        return PasswordStrengthLabel.Weak;
      }
    }
  }
}
