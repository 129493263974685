import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Store } from '@ngxs/store';

import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { ShowSpinner } from '../ngxs/root/root.actions';

@Injectable({ providedIn: 'root' })
export class ErrorHandlerInterceptor implements HttpInterceptor {
  constructor(
    private readonly _store: Store,
  ) {
  }

  public intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    return next.handle(request)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          this._store.dispatch(new ShowSpinner(false));

          throw error;
        }),
      );
  }
}
