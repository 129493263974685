import { Component, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { Store } from '@ngxs/store';

import { RecoverPassword } from '../../core/ngxs/auth/auth.actions';
import { RouterService } from '../../core/services/router.service';
import { QuestionBase } from '../../shared/classes/questions/question-base';
import { QuestionTextbox } from '../../shared/classes/questions/question-textbox';
import { RecoverPasswordData } from '../../shared/models/auth.models';
import { usernameValidator } from '../../shared/validators/username.validators';

@Component({
  selector: 'rs-accounts-password-recovery',
  templateUrl: './password-recovery.component.html',
  styleUrls: ['./password-recovery.component.scss'],
})
export class PasswordRecoveryComponent implements OnInit {
  public questions: QuestionBase[];

  constructor(
    private readonly _router: Router,
    private readonly _store: Store,
    private readonly _routerService: RouterService,
  ) {
  }

  ngOnInit(): void {
    this.questions = [
      new QuestionTextbox({
        key: 'username',
        label: 'fields.labels.username',
        validators: [
          Validators.required,
          usernameValidator,
        ],
      }),
    ];
  }

  public handleRecoverPasswordClick(data: RecoverPasswordData): void {
    this._store.dispatch(new RecoverPassword(data));
  }

  public handleSignupButtonClick(): void {
    this._router.navigate(['/registration']);
  }

  public handleLoginButtonClick(): void {
    this._routerService.navigate('/login');
  }
}
