import { Injectable } from '@angular/core';

import { NotificationsService } from 'angular2-notifications';

@Injectable({ providedIn: 'root' })
export class NotificationService {
  private readonly _options = {
    timeOut: 5000,
    showProgressBar: true,
    pauseOnHover: true,
    clickToClose: true,
  };

  constructor(
    private readonly _notificationService: NotificationsService,
  ) {
  }

  public showSuccessMessage(
    title: string,
    content?: string,
    options?: any,
  ): void {
    this._notificationService.success(title, content, { ...this._options, ...options });
  }

  public showErrorMessage(
    title: string,
    content?: string,
    options?: any,
  ): void {
    this._notificationService.error(title, content, { ...this._options, ...options });
  }
}
