<div [formGroup]="formGroup">
  <div [ngSwitch]="question.controlType">
    <rs-accounts-textbox
      *ngSwitchCase="QuestionType.Textbox"
      [control]="formGroup?.get(question.key)"
      [appearance]="question.appearance"
      [label]="question.label"
      [type]="question.type"
      [focus]="question.focus"
      [constraints]="question.constraints"
      [icon]="question.icon"
      [iconStyle]="question.iconStyle"
      [hasInitialError]="question.hasInitialError"
      [keyButtons]="question.keyButtons"
      [isDate]="question.isDate"
      [isCustomCopyPaste]="question.isCustomCopyPaste"
      [onFocus]="question.onFocus"
      [onKeyButtonPress]="question.onKeyButtonPress">
    </rs-accounts-textbox>

    <rs-accounts-checkbox
      *ngSwitchCase="QuestionType.Checkbox"
      [control]="formGroup?.get(question.key)"
      [label]="question.label"
      [innerHtml]="question.innerHtml">
    </rs-accounts-checkbox>

    <ng-container *ngSwitchCase="QuestionType.Custom">
      <ng-container 
        *ngTemplateOutlet="customQuestion; context: { $implicit: question.getTemplateContext() }">
      </ng-container>
    </ng-container>
  </div>
</div>
