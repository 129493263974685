import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';

import { Store } from '@ngxs/store';

import { CookieService } from 'ngx-cookie-service';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Client } from 'src/app/shared/enum/user.enum';

import { ShowSpinner } from '../ngxs/root/root.actions';
import { LocalStorageService } from '../services/local-storage.service';
import { UserService } from '../services/user.service';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private readonly _store: Store,
    private readonly _localStorageService: LocalStorageService,
    private readonly _cookieService: CookieService,
    private readonly _userService: UserService,
  ) {
  }

  canActivate(): Observable<boolean> | boolean {
    const accessToken = this._cookieService.get('access_token');

    if (!accessToken) {
      return true;
    }

    this._cookieService.delete('access_token');

    this._store.dispatch(new ShowSpinner(true));

    return this._userService.fetchClientUrls(accessToken)
      .pipe(
        map(clientUrlsResponse => {
          this._store.dispatch(new ShowSpinner(true));

          const client = this._localStorageService.getItem('client');

          this._localStorageService.removeItem('client');

          if (client?.trim() === 'null') {
            location.href = `https://${clientUrlsResponse['BCKOF' as Client]}`;
          } else {
            location.href = `https://${clientUrlsResponse[client as unknown as Client]}`;
          }
      
          return false;
        }),
      );
  }
}
