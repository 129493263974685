import { NgModule } from '@angular/core';

import { SharedModule } from '../../modules/shared.module';

import { ButtonModule } from '../button/button.module';
import { CheckboxModule } from '../questions/checkbox/checkbox.module';
import { TextboxModule } from '../questions/textbox/textbox.module';

import { FormComponent } from './form.component';
import { QuestionComponent } from './question/question.component';

@NgModule({
  imports: [
    SharedModule,
    ButtonModule,
    CheckboxModule,
    TextboxModule,
  ],
  declarations: [
    FormComponent,
    QuestionComponent,
  ],
  exports: [
    FormComponent,
    QuestionComponent,
  ],
})
export class FormModule {}
