import { ActivatedRoute } from '@angular/router';

import { UrlDataType } from '../enum/app.enum';
import { Map } from '../models/app.models';

const decodeQueryParamValue = (value: string): string => {
  if (value.includes('%')) {
    return decodeQueryParamValue(decodeURIComponent(value));
  }

  return value.replace(/[\= \&]/g, '');
};

const parseQueryStringParamValue = (paramKey: string): string => {
  const foundQueryParam = getQueryString()?.match(new RegExp(`${paramKey}=([^&]*)`, 'gi'));

  return foundQueryParam
    ? decodeQueryParamValue(foundQueryParam[0].replace(`${paramKey}=`, '').trim())
    : null;
};

export const getUrl = (): string => location.pathname;

export const getUrlParamValue = (
  route: ActivatedRoute,
  urlDataType: UrlDataType,
  key: string,
): string => route.snapshot[urlDataType].get(key) || '';

export const getQueryString = (
  queryParams: Map<any> = null,
  excludeQueryParams: string[] = [],
): string => {
  const queryString = decodeURIComponent(location.href.split('?')[1] || '');

  if (queryParams) {
    const params = new URLSearchParams(queryString);

    for (const excludeQueryParam of excludeQueryParams) {
      params.delete(excludeQueryParam);
    }

    Object
      .keys(queryParams)
      .forEach(key => params.set(key, encodeURIComponent(queryParams[key])));

    return params.toString();
  }

  return queryString;
}

export const getQueryParamValue = (paramKey: string): string =>
  parseQueryStringParamValue(paramKey);

export const getQueryParamValues = (paramKeys: string[]): string[] =>
  paramKeys
    .map(paramKey => parseQueryStringParamValue(paramKey))
    .filter(Boolean);

export const hasQueryStringParam = (key: string): boolean =>
  new URLSearchParams(getQueryString()).has(key);
