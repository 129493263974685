import { Component, OnDestroy } from '@angular/core';

import { Subscription } from 'rxjs';

@Component({ template: '' })
export class Unsubscribe implements OnDestroy {
  private subscriptions: Subscription[] = [];

  public set subscribeTo(subscription: Subscription) {
    this.subscriptions.push(subscription);
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }
}
