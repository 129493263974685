<div class="rs-intl-tel-widget">
  <input
    ng2TelInput
    [ng2TelInputOptions]="intlTelPhoneOptions"
    [formControl]="middlewareControl"
    [prefix]="getPrefix()"
    [mask]="getMask()"
    [showMaskTyped]="isTouched"
    (ng2TelOutput)="getNumber()"
    (countryChange)="handleCountryChange($event)"
    (click)="handleClick()"
    (focus)="handleClick()"
  />

  <div class="check-circle">
    <mat-icon>check</mat-icon>
  </div>

  <mat-form-field>
    <input
      matInput
      [formControl]="middlewareControl"
    >
    <mat-error *ngIf="middlewareControl.errors">{{ 'fields.errors.phoneNumber' | translate }}</mat-error>
  </mat-form-field>
</div>
